import {
    Route,
    Routes
} from 'react-router-dom';


import {IndexPage} from "../sign-in-profiles/pages/IndexPage.tsx";
import {AcceptancePage} from "./pages/AcceptancePage.tsx";
import {AcceptanceConfirmationPage} from "./pages/AcceptanceConfirmationPage.tsx";
import {AcceptanceProblemPage} from "./pages/AcceptanceProblemPage.tsx";
import {ViewTermsOfUsePage} from "./pages/ViewTermsOfUsePage.tsx";
import {IndexPage as UserAgreementsIndexPage} from "./pages/IndexPage.tsx";

export const UserAgreementsLayout = () => (
    <Routes>
        <Route path="/" element={<IndexPage/>}/>
        <Route path="acceptance" element={<AcceptancePage/>}/>
        <Route path="acceptance-confirmation" element={<AcceptanceConfirmationPage/>}/>
        <Route path="acceptance-problem" element={<AcceptanceProblemPage/>}/>
        <Route path="terms-of-use" element={<ViewTermsOfUsePage/>}/>
        <Route path="list" element={<UserAgreementsIndexPage/>}/>

    </Routes>
);
