import {
    createAsyncThunk,
    createSlice
} from '@reduxjs/toolkit';

import {
    add,
    deleteAllRecords,
    deleteRecord,
    fetch,
    fetchRecords,
    swap,
    update,
} from '../services/ApplicationsService';

import {
    deleteAllCase,
    deleteOneCase,
    fetchManyCase,
    fetchOneCase,
    saveOneCase,
    updateOneCase,
} from '../../../store/RecordsSliceCaseHelpers';
import {Application} from "../types.ts";


const entityKey = 'applications';

const initialState = {
    byId: {},
    ids: [],
    totalCount: 0,
    loading: false,
    error: '',
} as {
    byId: { [key: string]: any },
    ids: string[],
    totalCount: number,
    loading: boolean,
    error: string,
};


// genenrates pending, fulfilled and rejected
export const fetchApplications = createAsyncThunk(
    `${entityKey}/fetchRecords`,
    () => {
        return fetchRecords()
            .then((response) => {
                return response.map((record) => {
                    return record;
                });
            });
    }
);

export const fetchApplication = createAsyncThunk(
    `${entityKey}/fetch`,
    (id: string) => {
        return fetch(id)
            .then((response) => {
                return response;
            });
    });

export const saveApplication = createAsyncThunk(
    `${entityKey}/add`,
    async (payload: Application) => {
        return add(payload)
            .then((response) => response);
    }
);

export const updateApplication = createAsyncThunk(
    `${entityKey}/update`,
    ({
         id,
         record
     }: {
        id: string,
        record: Application
    }) => {
        return update(id, record)
            .then(() => record);
    }
);

export const deleteApplication = createAsyncThunk(
    `${entityKey}/deleteRecord`,
    (id: string) => {
        return deleteRecord(id)
            .then(() => id);
    }
);


export const deleteAllApplications = createAsyncThunk(
    `${entityKey}/deleteAllRecords`,
    () => {
        return deleteAllRecords()
            .then((response) => response);
    }
);

export const swapPositions = createAsyncThunk(
    `${entityKey}/swapPositions`,
    async () => {
        return swap();
    }
);

const applicationsSlice = createSlice({
                                          name: entityKey,
                                          initialState,
                                          reducers: {},
                                          extraReducers: (builder) => {
                                              // FETCH MANY
                                              fetchManyCase(builder, fetchApplications, entityKey);

                                              // FETCH ONE
                                              fetchOneCase(builder, fetchApplication);

                                              // SAVE ONE
                                              saveOneCase(builder, saveApplication);

                                              // UPDATE ONE
                                              updateOneCase(builder, updateApplication);

                                              // DELETE ONE
                                              deleteOneCase(builder, deleteApplication);

                                              // DELETE ALL
                                              deleteAllCase(builder, deleteAllApplications);

                                              // SWAP POSITIONS
                                              fetchManyCase(builder, swapPositions, entityKey);
                                          },
                                      });

export default applicationsSlice.reducer;
