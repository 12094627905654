import {
    createAsyncThunk,
    createSlice
} from '@reduxjs/toolkit';

import {Auth} from '@aws-amplify/auth';
import {notifyError} from "../../../components/ServiceResponseNotifications";
import {Account} from "../../accounts/types.ts";

const entityKey = 'credentials';

const initialState = {
    forcePasswordChange: false,
    loading: false,
    error: '',
};

export const signIn = createAsyncThunk(
    `${entityKey}/signInWithUsernameAndPassword`,
    ({
         account,
         username,
         password
     }: {
        account: Account,
        username: string,
        password: string
    }) => {
        Auth.configure({
                           userPoolId: account.cognitoUserPoolId,
                           userPoolWebClientId: account.appClientId,
                       });

        return Auth.signIn(username, password)
                   .then(
                       (cognitoUser) => cognitoUser
                   )
                   .catch(e => {
                       notifyError(e)
                   });
    }
);

const credentialsSlice = createSlice({
                                         name: entityKey,
                                         initialState,
                                         reducers: {
                                             clearError: (state,) => {
                                                 state.loading = true;
                                                 state.error = '';
                                             },
                                         },
                                         extraReducers: (builder) => {
                                             // SIGN IN
                                             builder.addCase(signIn.pending, (state) => {
                                                 state.loading = true;
                                             });

                                             builder.addCase(signIn.fulfilled, (state, action) => {

                                                 if (typeof action.payload === 'undefined') {
                                                     return;
                                                 }

                                                 const {payload: cognitoUser} = action;
                                                 if (cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
                                                     state.forcePasswordChange = true;
                                                 }

                                                 state.loading = false;
                                             });

                                             builder.addCase(signIn.rejected, (state, action) => {
                                                 state.loading = false;
                                                 state.error = action.error.message as string;
                                             });
                                         },
                                     });

export default credentialsSlice.reducer;

export const {clearError} = credentialsSlice.actions;
