import {AwsRum} from 'aws-rum-web'
import config from "../config";


const RUM = config.RUM

if (RUM.isEnabled === true) {
    try {
        new AwsRum(
            RUM.applicationId,
            RUM.applicationVersion,
            RUM.applicationRegion,
            RUM.config
        )
    } catch (error) {
        // Ignore errors thrown during CloudWatch RUM web client initialization
    }
}
