export default {
    Home: 'घर',
    'Sign In': 'साइन इन',
    'Sign Out': 'प्रस्थान',
    'Signing In': 'साइन इन कर रहे हैं, कृपया प्रतीक्षा करें',
    'Signing Out': 'प्रस्थान कर रहे हैं, कृपया प्रतीक्षा कर',
    'Signed In with': 'Signed In विथ',
    'to continue to': 'to continue to',
    Next: 'Next',
    'get verification code': 'सत्यापन कोड प्राप्त करें',
    Working: 'Working',
    'Please wait': 'कृपया प्रतीक्षा कर',
    'Select Sign In Method': 'साइन इन विधि का चयन करें',
    Continue: 'कन्टिन्यू',
    'Continue with SSO': 'SSO के साथ साइन इन करें',
    'Continue with password': 'पासवर्ड के साथ साइन इन कर',
    'You have enabled Sign-In using both password and Single Sign-On (SSO)':
        'आपने पासवर्ड और सिंगल साइन-ऑन (SSO) दोनों का उपयोग करके साइन-इन सक्षम किया है',
    'Please make a selection below or change sign-in options':
        'कृपया नीचे चयन करें या वरीयताएँ बदलें',
    Name: 'नाम',
    'Email Address': 'ईमेल',
    Password: 'पासवर्ड',
    Preferences: 'पसंद',
    'Forgotten password': 'भुला दिया पासवर्ड',
    Language: 'भाषा',
    Submit: 'प्रस्तुत',
    Cancel: 'रद्द',
    Update: 'अपडेट',
    or: 'या',
    'Terms Of Service': 'सेवा की शर्तें',
    'Privacy Policy': 'गोपनीयता नीति',
    'Contact Us': 'हमें संपर्क करें',
};
