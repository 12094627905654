import {showNotification} from '@mantine/notifications';
import {
    AiOutlineExclamation,
    AiOutlineInfo,
    AiOutlineLock,
    AiOutlineLogin,
} from 'react-icons/ai';


export const notifySuccess = (message = 'Operation Success') => {

    showNotification({
                         title: 'Success',
                         icon: <AiOutlineInfo/>,
                         message: message,
                         color: 'green',
                     });


};

export const notifyError = (e: object | any) => {

    console.trace(e);

    if (typeof e === 'string') {
        throw new Error(e);
    }

    if (e instanceof Error) {

        const myError = e as Error & { response: { data: { message: string } } };

        let errorMessage = e.message;

        if (typeof myError.response !== 'undefined') {
            if (typeof myError.response.data.message !== 'undefined') {
                errorMessage = myError.response.data.message;
            }
        }

        showNotification({
                             title: 'Error',
                             icon: <AiOutlineExclamation/>,
                             message: errorMessage,
                             color: 'red',
                         });

        // throw new Error(e.message);

        const noCurrentUserMessage = 'no current user'
        if (errorMessage.toLowerCase() === noCurrentUserMessage) {
            notifySessionExpired();
        }

    }

    if (Object.prototype.hasOwnProperty.call(e, 'errors') && typeof e.errors !== 'undefined' && Array.isArray(e.errors)) {
        e.errors.forEach((error: any) => {
            showNotification({
                                 title: 'Error',
                                 icon: <AiOutlineExclamation/>,
                                 message: error.message,
                                 color: 'red',
                             });
        })
    }

};

export const notifyAccessDenied = (message = 'Access Denied') => {

    showNotification({
                         title: 'Access Control',
                         icon: <AiOutlineLock/>,
                         message: message,
                         color: 'red',
                     });


};


export const notifySessionExpired = (message = 'Redirecting to login page... Please, wait!') => {

    showNotification({
                         title: 'Session Expired',
                         icon: <AiOutlineLogin/>,
                         message: message,
                         color: 'blue',
                     });

    setTimeout(() => {
        window.location.reload();
    }, 3000);

};