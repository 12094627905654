import {
    createContext,
    ReactNode,
    useEffect
} from 'react';
import PropTypes from 'prop-types';

import {fetchAccounts} from '../reducers/recordsSlice';

import {accountsSelector} from '../selectors';
import {
    useAppDispatch,
    useAppSelector
} from "../../../hooks/useTypedReactRedux.ts";
import {RootState} from "../../../store";
import {Account} from "../types.ts";

type AccountsContextType = {
    accounts: Account[] | [],
}

export const AccountsContext = createContext<AccountsContextType>({accounts: []});

export const AccountsContextProvider = ({children}: { children: ReactNode }) => {
    const dispatch = useAppDispatch();

    const accounts = useAppSelector((state: RootState) => accountsSelector(state));

    useEffect(() => {
        dispatch(fetchAccounts());
    }, [dispatch]);

    return (
        <AccountsContext.Provider value={{accounts}}>
            {children}
        </AccountsContext.Provider>
    );
};

AccountsContextProvider.propTypes = {
    children: PropTypes.object,
};
