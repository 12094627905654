import {
    createAsyncThunk,
    createSlice
} from '@reduxjs/toolkit';

import {Auth} from '@aws-amplify/auth';
import {Account} from "../../accounts/types.ts";
import {Credentials} from "../../credentials/types.ts";

const entityKey = 'resetPassword';

const initialState = {
    requestResult: '',
    requestDetails: null,
    confirmResult: '',
    loading: false,
    error: '',
};

// genenrates pending, fulfilled and rejected
export const requestPasswordChange = createAsyncThunk(
    `${entityKey}/request`,
    ({
         account,
         emailAddress
     }: {
        account: Account, emailAddress: string
    }) => {
        Auth.configure({
                           userPoolId: account.cognitoUserPoolId,
                           userPoolWebClientId: account.appClientId,
                       });

        return Auth.forgotPassword(emailAddress)
                   .then((response) => response);
    }
);

export const confirmPasswordChange = createAsyncThunk(
    `${entityKey}/confirm`,
    ({
         account,
         values
     }: {
        account: Account,
        values: Credentials
    }) => {
        Auth.configure({
                           userPoolId: account.cognitoUserPoolId,
                           userPoolWebClientId: account.appClientId,
                       });

        return Auth.forgotPasswordSubmit(
            account.username as string,
            values.otc as string,
            values.new_password as string
        )
                   .then((response) => response);
    }
);

const resetPasswordsSlice = createSlice({
                                            name: entityKey,
                                            initialState,
                                            reducers: {},
                                            extraReducers: (builder) => {
                                                // PASSWORD RESET
                                                builder.addCase(requestPasswordChange.pending, (state) => {
                                                    state.loading = true;
                                                });

                                                builder.addCase(requestPasswordChange.fulfilled, (state, action) => {
                                                    const {payload} = action;
                                                    state.requestDetails = payload.CodeDeliveryDetails;
                                                    state.requestResult = 'success';
                                                    state.loading = false;
                                                });

                                                builder.addCase(requestPasswordChange.rejected, (state, action) => {
                                                    state.loading = false;
                                                    state.error = action.error.message as string;
                                                });

                                                // CHANGE CONFIRM
                                                builder.addCase(confirmPasswordChange.pending, (state) => {
                                                    state.loading = true;
                                                });

                                                builder.addCase(confirmPasswordChange.fulfilled, (state, action) => {
                                                    const {payload} = action;
                                                    console.debug('payload in confirmPasswordChange', payload)
                                                    state.confirmResult = 'success';
                                                    state.loading = false;
                                                });

                                                builder.addCase(confirmPasswordChange.rejected, (state, action) => {
                                                    state.loading = false;
                                                    state.error = action.error.message as string;
                                                });
                                            },
                                        });

export default resetPasswordsSlice.reducer;
