import {ActionsControl} from './ActionsControl.js';
import {useApplications} from "../../applications/hooks/useApplications";

export const ApplicationsControl = () => {

    const {
        nonDefaultApplications,
        handleDeleteNonDefaultApplications
    } = useApplications();

    return (
        <ActionsControl
            label="Return-To Applications"
            resourceCount={nonDefaultApplications.length}
            onClickHandler={handleDeleteNonDefaultApplications}
        />
    );
};
