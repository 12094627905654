import {
    Navigate,
    useParams
} from 'react-router-dom';

import {SelectionComponent} from '../components/SelectionComponent';

import {authorizeUrlHelper} from '../../../utils/AuthorizeUrlHelper';
import {update} from '../../accounts/services/AccountsService';
import {PlatformLoader} from "@pac/platform-ui-components";
import {useAccount} from "../../accounts/hooks/useAccount.ts";
import {Account} from "../../accounts/types.ts";
import {hasIdProvider} from "../../federated-id-providers/services/FederatedIdProviderService.tsx";
import {useEffect} from "react";
import {useSignInProfiles} from "../../sign-in-profiles/hooks/useSignInProfiles.ts";


const updateAndRedirectForAuthorization = async (account: Account) => {
    let authorizeUrl = await authorizeUrlHelper(account);
    const flagged = Object.assign({}, account, {
        isAuthorizing: 'yes',
    });

    update(account.id, flagged)
        .then(() => {
            window.location.assign(authorizeUrl);
        });

};

export const IndexPage = () => {

    const {account: accountId} = useParams();

    const {
        record: account,
    } = useAccount(accountId);

    const {
        activeProfile,
        handleFetchSignInProfiles,
    } = useSignInProfiles();

    useEffect(() => {
        handleFetchSignInProfiles();
    }, [])


    if (typeof activeProfile === 'undefined') {
        return <PlatformLoader message="Loading sign-in profile...Please wait"/>;
    }

    if (!Object.prototype.hasOwnProperty.call(activeProfile, 'passwordAndSso')) {
        throw new Error('passwordAndSso required parameter');
    }

    const {passwordAndSso} = activeProfile;


    if (typeof account !== 'undefined') {
        if (hasIdProvider(account) === true) {
            if (passwordAndSso === true) {
                return (
                    <SelectionComponent
                        handleSSOButtonClick={() =>
                            updateAndRedirectForAuthorization(account)
                        }
                    />
                );
            } else {
                updateAndRedirectForAuthorization(account);
                return <PlatformLoader message="Signing In...Please wait"/>;
            }
        } else {
            return <Navigate to={`/accounts/${account.id}/credentials`}/>;
        }
    } else {
        return <PlatformLoader message="No Account Found...Please wait"/>;
    }
};
