import {
    Route,
    Routes
} from 'react-router-dom';

import {IndexPage} from './pages/IndexPage.tsx';
import {AddPage} from './pages/AddPage.tsx';
import {EditPage} from './pages/EditPage.tsx';

const SignInProfilesLayout = () => {

    return (
        <Routes>
            <Route path="/" element={<IndexPage/>}/>
            <Route path="add" element={<AddPage/>}/>
            <Route path=":id" element={<EditPage/>}/>
        </Routes>
    );
};

export default SignInProfilesLayout;
