import {Navigate} from 'react-router-dom';

import {I18n} from '@aws-amplify/core';

import {ChangePasswordForm} from '../forms/ChangePasswordForm.js';

import {
    Box,
    SimpleGrid,
    Title
} from '@mantine/core';
import {useChangePassword} from "../hooks/useChangePassword";
import {Credentials} from "../../credentials/types.ts";

export const IndexPage = () => {

    const {
        changePasswordResult,
        handleChangePassword
    } = useChangePassword();

    if (changePasswordResult === 'success') {
        return <Navigate to="/"/>;
    }

    const record = {} as Credentials;

    return (
        <SimpleGrid>
            <Title order={2}>{I18n.get('Change Password')}</Title>
            <Box>{I18n.get('to continue to')} NEXT Cloud</Box>
            <Box mt="sm">
                <ChangePasswordForm record={record} handleSubmit={handleChangePassword}/>
            </Box>
        </SimpleGrid>
    );
};
