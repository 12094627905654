import PropTypes from 'prop-types';

import {
    Formik,
    FormikProps
} from 'formik';

import {FormComponent} from './FormComponent';


import {ValidationSchema} from './ValidationSchema';
import {
    DisplayFormikState,
    FormWithActionBar
} from "@pac/platform-ui-components";
import {Credentials} from "../../credentials/types.ts";

export const ConfirmForm = ({
                                record,
                                handleSubmit
                            }: {
    record: Credentials,
    handleSubmit: (values: Credentials) => void
}) => {
    const onSubmit = (values: object) => {
        return handleSubmit(values);
    };

    const renderForm = (props: FormikProps<any>) => {
        return (
            <FormWithActionBar handleSubmit={props.handleSubmit}>
                <FormComponent {...props} />
                {false && <DisplayFormikState {...props} />}
            </FormWithActionBar>
        );
    };

    let initialValues = {} as Credentials;

    if (record) {
        initialValues = record;
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={ValidationSchema}
            onSubmit={onSubmit}
        >
            {renderForm}
        </Formik>
    );
};

ConfirmForm.propTypes = {
    record: PropTypes.object,
    handleSubmit: PropTypes.func,
};

export default ConfirmForm;
