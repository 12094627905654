import {
    Navigate,
    useParams
} from 'react-router-dom';

import {I18n} from '@aws-amplify/core';

import {EmailForm} from '../../accounts/forms/EmailForm';

import {
    Stack,
    Title
} from '@mantine/core';
import {Credentials} from "../../credentials/types.ts";
import {useResetPassword} from "../hooks/useResetPassword.ts";
import {useAccount} from "../../accounts/hooks/useAccount.ts";
import {Account} from "../../accounts/types.ts";


export const RequestPage = () => {

    const {account: accountId} = useParams();

    const {
        record: account,
    } = useAccount(accountId);

    const {
        resetPassword,
        requestResult
    } = useResetPassword();

    const handleSubmit = (values: Credentials) => {
        resetPassword(values);
    };

    if (requestResult === 'success') {
        return <Navigate to={`/accounts/${account.id}/reset-password/confirm`}/>;
    }

    let initialValues = {} as Account;
    if (typeof account !== 'undefined') {
        if (account !== null) {
            initialValues.email = account['emailAddress'] as string;
        }
    }

    return (
        <Stack my={'lg'}>
            <Title order={3} tt={'uppercase'}>{I18n.get('Reset Password')}</Title>
            <EmailForm record={initialValues} handleSubmit={handleSubmit}/>
        </Stack>
    );
};
