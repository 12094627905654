import {useEffect} from 'react';

import {ActionsControl} from './ActionsControl.js';
import {useAccounts} from "../../accounts/hooks/useAccounts";

export const UserAccountsControl = () => {

    const {
        accounts,
        handleFetchAccounts,
        handleDeleteAllAccounts
    } = useAccounts()

    useEffect(() => {
        handleFetchAccounts();
    }, []);


    return (
        <ActionsControl
            label="Accounts"
            resourceCount={accounts.length}
            onClickHandler={handleDeleteAllAccounts}
        />
    );
};
