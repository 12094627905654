import {
    deleteSignInProfile,
    fetchSignInProfile,
    saveSignInProfile,
    updateSignInProfile
} from "../reducers/recordsSlice";
import {
    signInProfileSelector,
    signInProfilesSelector
} from "../selectors";
import {I18n} from '@aws-amplify/core';
import {
    useAppDispatch,
    useAppSelector
} from "../../../hooks/useTypedReactRedux.ts";
import {RootState} from "../../../store";
import {SignInProfile} from "../types.ts";

export const useSignInProfile = (id?: string) => {

    const dispatch = useAppDispatch();

    const signInProfiles = useAppSelector((state: RootState) => signInProfilesSelector(state));

    const record = useAppSelector((state: RootState) => signInProfileSelector(state, id ? id : ''));

    const handleFetchSignInProfile = () => {
        if (typeof id === 'undefined') {
            throw new Error('id is undefined');
        }
        dispatch(fetchSignInProfile(id));
    };

    const handleAddSignInProfile = (values: SignInProfile) => {
        const payload = {
            ...values,
            id: `sign-in-profile-${Date.now()}`,
        };
        dispatch(saveSignInProfile(payload));
    };

    const handleUpdateSignInProfile = (values: SignInProfile) => {
        if (typeof id === 'undefined') {
            throw new Error('id is undefined');
        }
        dispatch(updateSignInProfile({
                                         id: id,
                                         record: values
                                     }));
    };

    const handleMakeActive = (id: string) => {
        signInProfiles.forEach((option) => {
            let record;
            if (option.id === id) {
                record = {...option, ...{isActive: true}};
                I18n.setLanguage(option.language);
            } else {
                record = {...option, ...{isActive: false}};
            }
            dispatch(updateSignInProfile({
                                             id: option.id as string,
                                             record: record
                                         }));
        });
    };

    const handleDeleteSignInProfile = (id: string) => {
        dispatch(deleteSignInProfile(id));
    };

    return {
        record,
        handleFetchSignInProfile,
        handleAddSignInProfile,
        handleUpdateSignInProfile,
        handleMakeActive,
        handleDeleteSignInProfile
    };
};