import {Cache} from 'aws-amplify';
import {platform} from "../config/platform.ts";
import {Application} from "../features/applications/types.ts";

const APPLICATIONS_STORAGE_KEY = 'pac-applications';

const addDefaultApplication = () => {

    const url = new URL(platform.portal as string);
    const locationSearch = window.location.search;

    // delimiter looks like this - ?returnTo=https://myapp.nexcloud.aero/my-path;
    const delimiter = '?returnTo=' + url.origin + url.pathname;
    // console.debug('delimiter', delimiter);

    // returnToSearch looks like this - ?foo=bar&zet=zero;
    let returnToSearch = locationSearch.substring(delimiter.length);
    // console.debug('returnToSearch', returnToSearch);

    if (returnToSearch.charAt(0) === '?') {
        returnToSearch = returnToSearch.substring(1);
    }

    const application = {
        id: `application-${Date.now()}`,
        returnToSearch: returnToSearch,
        host: url.host,
        hostname: url.hostname,
        origin: url.origin,
        pathname: url.pathname,
        port: url.port,
        protocol: url.protocol,
        isDefault: true,
        createdAt: new Date().toISOString(),
    } as Application;

    console.debug('adding default return to  application', application)

    // store
    Cache.setItem(APPLICATIONS_STORAGE_KEY, [application]);
}


const applications = Cache.getItem(APPLICATIONS_STORAGE_KEY, {
    callback: () => {
        return [];
    },
});


if (applications.length === 0) {
    Cache.removeItem(APPLICATIONS_STORAGE_KEY);
    addDefaultApplication();

} else {

    // find default application
    const defaultApplication = applications.find((application: Application) => application.isDefault);

    if (!defaultApplication) {
        Cache.removeItem(APPLICATIONS_STORAGE_KEY);
        addDefaultApplication();
    }

}
