import * as Yup from 'yup';

const passwordRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})");

export const ValidationSchema = Yup.object()
                                   .shape({
                                              'new_password': Yup.string()
                                                                 .min(8, 'Too Short! It must be at least 8 characters long')
                                                                 .max(30, 'Too Long!')
                                                                 .matches(passwordRegex, "Password should have at least one special character and one digit")
                                                                 .required('Required'),
                                              'confirm_password': Yup.string()
                                                                     .oneOf([Yup.ref('new_password')], "Passwords don't match")
                                                                     .required('Required'),
                                              'ts': Yup.boolean()
                                                       .oneOf([true], 'Must Accept Terms and Conditions')

                                          });

