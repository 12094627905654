import * as Yup from 'yup';

const ChangePasswordValidationSchema = Yup.object().shape({
    'sso': Yup.boolean()
        .oneOf([true], 'Single Sign On Agreement acceptance is required'),
    'terms': Yup.boolean()
        .oneOf([true], 'Terms of Use acceptance is required'),

});

export default ChangePasswordValidationSchema;
