import PropTypes from 'prop-types';

import {
    ErrorMessage,
    Field,
    FormikProps,
} from 'formik';


import {
    Box,
    Group,
    SimpleGrid
} from '@mantine/core';


import {
    DisplayFormikState,
    PacFormikInput
} from "@pac/platform-ui-components";
import {Credentials} from "../../credentials/types.ts";

export const FormComponent = (props: FormikProps<Credentials>) => (
    <>
        <SimpleGrid>
            <Box>
                <Field
                    type="password"
                    name="new_password"
                    placeholder="New Password"
                    component={PacFormikInput}
                />
            </Box>

            <Box>
                <Field
                    type="password"
                    name="confirm_password"
                    placeholder="Confirm Password"
                    component={PacFormikInput}
                />
            </Box>

            <Box>
                <Group>
                    <Field id="ts" type="checkbox" name="ts"/>
                    <label htmlFor="ts">I Agree to the terms of use</label>
                </Group>
                <ErrorMessage name="ts" component="div"/>
            </Box>

        </SimpleGrid>

        {false && <DisplayFormikState {...props} />}
    </>
);

FormComponent.propTypes = {
    handleSubmit: PropTypes.func,
    isSubmitting: PropTypes.bool,
};
