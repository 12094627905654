export enum VerbosityLevel {
    LOW = 'low',
    MEDIUM = 'medium',
    HIGH = 'high',
}

export type SignInProfile = {
    id?: string,
    language: string
    showReturnTo: boolean;
    passwordAndSso: boolean;
    maxReturnTo: number;
    verbosity: string;
    isDefault: boolean;
    isActive: boolean
    createdAt?: string
}