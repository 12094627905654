import {ApplicationsControl} from '../components/ApplicationsControl';
import {SignInProfilesControl} from '../components/SignInProfilesControl.tsx';
import {VersionControl} from '../components/VersionControl';
import {UserAccountsControl} from '../components/UserAccountsControl';

import {I18n} from '@aws-amplify/core';

import {
    Box,
    Center,
    Stack,
    Table,
    Title
} from '@mantine/core';
import config from "../../../config";


const {about} = config;


export const IndexPage = () => {
    return (
        <Stack spacing="lg">
            <Title order={3} data-testid="page-title">
                {I18n.get('About')}
            </Title>

            <Table verticalSpacing={'sm'} striped>
                <tbody>
                <tr>
                    <td>
                        <Box>{I18n.get('Application Name')}:</Box>
                    </td>
                    <td>
                        <Center><Box>{about.name}</Box></Center>
                    </td>
                    <td>
                        <Box></Box>
                    </td>
                </tr>
                <VersionControl/>
                <UserAccountsControl/>
                <ApplicationsControl/>
                <SignInProfilesControl/>
                </tbody>
            </Table>
        </Stack>
    );
};
