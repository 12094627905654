import {combineReducers} from 'redux';

import recordsReducer from './recordsSlice';
import {SelectionSliceFor} from "../../../store/SelectionSliceFor.ts";

const entityKey = 'credentials';

const selectionSlice = SelectionSliceFor(entityKey);

export const {clearSelection} = selectionSlice.actions;

export const CredentialsReducer = combineReducers({
                                                      records: recordsReducer,
                                                  });

