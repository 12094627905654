import {useNavigate} from 'react-router-dom';
import {EmailForm} from '../forms/EmailForm.js';
import {FormikBag} from 'formik';
import {useAccounts} from "../hooks/useAccounts";
import {HeadingRow} from "../../credentials/components/HeadingRow.tsx";
import {Account} from "../types.ts";

interface Props {
    record: Account
}

interface Values {
    email: string
}

export const AddPage = () => {
    const navigate = useNavigate();

    const {handleSearchAccounts} = useAccounts();

    const handleSubmit = (values: Account) => {

        handleSearchAccounts(values.email)

        const timer = setTimeout(() => {
            navigate('/accounts');
            return false;
        }, 1000);
        return () => clearTimeout(timer);
    };

    const handleReset = (_: any, formikBag: FormikBag<Props, Values>) => {
        formikBag.resetForm();
        return false;
    };

    let initialValues = {email: ''} as Account;

    return (
        <>
            <HeadingRow title="Add an account:" subtitle=""/>
            <EmailForm
                handleSubmit={handleSubmit}
                handleReset={handleReset}
                record={initialValues}
            />
        </>
    );
};
