import {createSlice} from '@reduxjs/toolkit';
import {ISelectionSlice} from "../types";

const initialState: ISelectionSlice = {};

export const SelectionSliceFor = (entityKey: string) => {
    const selectionSlice = createSlice({
                                           name: entityKey,
                                           initialState,
                                           reducers: {
                                               addToSelection: (state, action) => {
                                                   const record = action.payload;
                                                   state[record.id] = record;
                                               },
                                               removeFromSelection: (state, action) => {
                                                   delete state[action.payload];
                                               },
                                               clearSelection: (state) => {
                                                   Object.keys(state)
                                                         .forEach((key) => {
                                                             delete state[key];
                                                         });
                                               },
                                           },
                                       });

    return selectionSlice;
};
