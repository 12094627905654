import * as Yup from 'yup';

const RecordSchema = Yup.object().shape({
    language: Yup.string()
        .min(1, 'Too Short!')
        .max(150, 'Too Long!')
        .required('Required'),
});

export default RecordSchema;
