import {stage} from "../stage.ts";
import {platform} from "../platform.ts";

type ConfigShape = {
    [key: string]: {
        userPoolDomain?: string,
        identityPoolId: string,
        region: string,
        userPoolId?: string,
        userPoolWebClientId?: string,
        cookieStorageDomain?: string,
    }
}

const cognito = {
    prd: {
        userPoolDomain: '',
        identityPoolId: 'us-west-2:68a710d8-3068-4fbb-b147-af24c30e2a8d',
        region: 'us-west-2',
        userPoolId: '',
        userPoolWebClientId: '',
    },
    test: {
        userPoolDomain: '',
        identityPoolId: 'us-west-2:b24d69a8-ea6d-44ac-a2f3-7862de54d31a',
        region: 'us-west-2',
        userPoolId: '',
        userPoolWebClientId: '',
    },
    dev: {
        userPoolDomain: '',
        identityPoolId: 'us-west-2:00fa6f33-0ffc-4e24-8cd7-b34bfb6ffd1d',
        region: 'us-west-2',
        userPoolId: '',
        userPoolWebClientId: '',
    },
    ide: {
        userPoolDomain: '',
        identityPoolId: 'us-west-2:00fa6f33-0ffc-4e24-8cd7-b34bfb6ffd1d',
        region: 'us-west-2',
        userPoolId: '',
        userPoolWebClientId: '',
    },
} as ConfigShape;


const oauth = {
    // Domain name
    domain: cognito[stage].userPoolDomain,

    // Authorized scopes
    scope: [
        'phone',
        'email',
        'profile',
        'openid',
        'aws.cognito.signin.user.admin',
    ],

    // Callback URL
    redirectSignIn: platform.login,

    // // Sign out URL
    redirectSignOut: platform.login,

    // 'code' for Authorization code grant,
    // 'token' for Implicit grant
    responseType: 'code',

    // optional, for Cognito hosted ui specified options
    options: {
        // Indicates if the data collection is enabled to support Cognito advanced security features. By default, this flag is set to true.
        AdvancedSecurityDataCollectionFlag: true,
    },
};

let Auth = {
    // REQUIRED - Amazon Cognito Identity Pool ID
    identityPoolId: cognito[stage].identityPoolId,

    // REQUIRED - Amazon Cognito Region
    region: cognito[stage].region,

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: cognito[stage].userPoolId,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: cognito[stage].userPoolWebClientId,

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: false,
    //cookieStorage: cookieStorage,
    oauth: oauth,
};

export default Auth;
