import {I18n} from '@aws-amplify/core';

import {RecordForm} from '../forms/RecordForm.js';

import {FormPageLayout} from '@pac/platform-ui-components';
import {useSignInProfile} from "../hooks/useSignInProfile.ts";

export const AddPage = () => {

    const {
        record,
        handleAddSignInProfile
    } = useSignInProfile()

    return (
        <FormPageLayout title={I18n.get('Add Sign-in Profile')} tools={[]}>
            <RecordForm
                record={record}
                handleSubmit={handleAddSignInProfile}
            />
        </FormPageLayout>
    );
};
