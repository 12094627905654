import {I18n} from '@aws-amplify/core';


import {Field} from 'formik';


import {
    SimpleGrid,
    Stack,
    Text,
    Title
} from '@mantine/core';
import {
    PacFormikDropdown,
    PacFormikRadioGroup,
    PacFormikSwitch
} from "@pac/platform-ui-components";
import {VerbosityLevel} from "../types.ts";

const languages = [
    {
        id: 'en',
        label: 'English'
    },
    {
        id: 'ja',
        label: 'Japanese'
    },
    {
        id: 'bg',
        label: 'Bulgarian'
    },
    {
        id: 'hi',
        label: 'Hindi'
    },
];

const verbosityOptions = [
    {
        value: VerbosityLevel.LOW,
        label: 'low'
    },
    {
        value: VerbosityLevel.MEDIUM,
        label: 'medium'
    },
    {
        value: VerbosityLevel.HIGH,
        label: 'high'
    },
];

const languagesOptions = languages.map((language) => {
    return {
        value: language.id,
        label: language.label
    };
});

export const FormComponent = () => {

    return (
        <Stack spacing={'lg'}>
            <SimpleGrid>
                <Title order={4}>
                    Preferred <Text fw={800} display={'inline'}>{I18n.get('language')}</Text>
                </Title>
                <Field
                    autoFocus
                    type="text"
                    name="language"
                    data={languagesOptions}
                    component={PacFormikDropdown}
                />
            </SimpleGrid>

            <SimpleGrid>
                <Title order={4}>
                    Show list of <Text fw={800} display={'inline'}>"return to"</Text> applications
                </Title>
                <Field
                    name="showReturnTo"
                    options={{
                        labelWhenTrue: 'Show',
                        labelWhenFalse: 'Hide',
                    }}
                    component={PacFormikSwitch}
                />
            </SimpleGrid>

            <SimpleGrid>
                <Title order={4}>
                    Allow Selection of <Text fw={800} display={'inline'}>sign-in method</Text> (not common)
                </Title>
                <Field
                    name="passwordAndSso"
                    options={{
                        labelWhenTrue: 'Enabled',
                        labelWhenFalse: 'Disabled',
                    }}
                    component={PacFormikSwitch}
                />
            </SimpleGrid>

            <SimpleGrid>
                <Title order={4}>
                    <Text fw={800} display={'inline'}>Verbosity</Text> level
                </Title>
                <Field
                    name="verbosity"
                    component={PacFormikRadioGroup}
                    options={verbosityOptions}
                />
            </SimpleGrid>

            <SimpleGrid>
                <Title order={4}>
                    <Text fw={800} display={'inline'}>Activate</Text> this profile
                </Title>
                <Field
                    name="isActive"
                    options={{
                        labelWhenTrue: 'Yes',
                        labelWhenFalse: 'No',
                    }}
                    component={PacFormikSwitch}
                />
            </SimpleGrid>
        </Stack>
    );
};