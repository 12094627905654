import RUM from './cloudwatch-rum.ts';
import StaticAssets from './static-assets';
import ReturnToDomains from './return-to-domain-names';
import about from './about.js';

let config = {
    RUM: RUM,
    staticAssets: StaticAssets,
    allowedReturnToDomains: ReturnToDomains,
    about: about,
};

export default config;
