import {changePassword} from "../reducers/recordsSlice.ts";
import {Credentials} from "../../credentials/types.ts";
import {
    useAppDispatch,
    useAppSelector
} from "../../../hooks/useTypedReactRedux.ts";
import {useAccount} from "../../accounts/hooks/useAccount.ts";
import {CognitoUser} from 'amazon-cognito-identity-js';
import {changePasswordResultSelector} from "../selectors.ts";
import {useAuth} from "../../../hooks/useAuth.ts";
import {useParams} from "react-router-dom";

export const useChangePassword = () => {

    const {account: accountId} = useParams();

    const {
        record: account,
    } = useAccount(accountId);

    const {user} = useAuth({account});

    const dispatch = useAppDispatch();

    const changePasswordResult = useAppSelector((state) =>
                                                    changePasswordResultSelector(state)
    );

    const handleChangePassword = (values: Credentials) => {
        dispatch(
            changePassword({
                               account,
                               cognitoUser: user as CognitoUser,
                               newPassword: values.new_password as string
                           })
        );
    };


    return {
        handleChangePassword,
        changePasswordResult

    }


}