export default {
    Home: 'ホーム',
    'Sign In': 'サインイン',
    'Sign Out': 'サインアウト',
    'Signing In': 'サインイン中',
    'Signing Out': 'サインアウト中',
    'Signed In with': 'サインイン方法',
    'to continue to': '続行するために',
    Next: 'ネクスト',
    'get verification code': '検証コードを取得する',
    Working: '動作中',
    'Please wait': 'お待ち下さい',
    'Select Sign In Method': 'サインイン方法を選択してください',
    Continue: '続ける',
    'Continue with SSO': 'SSOで続ける',
    'Continue with password': 'パスワードで続ける',
    'You have enabled Sign-In using both password and Single Sign-On (SSO)':
        'パスワード、SSOのどちらでもサインイン可能です',
    'Please make a selection below or change sign-in options':
        '以下から選択、もしくは設定を変更してください',
    Name: '氏名',
    'Email Address': 'Eメールアドレス',
    Password: 'パスワード',
    Preferences: '設定',
    'Forgotten password': 'パスワードを忘れた場合',
    Language: '言語',
    Submit: '送信',
    Cancel: 'キャンセル',
    Update: 'アップデート',
    or: 'もしくは',
    'Terms Of Service': '利用規約',
    'Privacy Policy': '個人情報保護方針',
    'Contact Us': '連絡先',

};
