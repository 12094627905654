import PropTypes from 'prop-types';

import {SmallProfile} from '../components/SmallProfile';

import {SimpleGrid} from '@mantine/core';

import {AddAccountProfile} from './AddAccountProfile.js';

import {useNavigate} from 'react-router-dom';
import {Account} from "../types.ts";


const ProfilesGrid = ({
                          accounts,
                          handleContinue,
                          handleSignOut,
                          handleSignOutAndForget,
                          handleHideAccount,
                      }: {
    accounts: Account[],
    handleContinue: (account: Account) => void,
    handleSignOut: (account: Account) => void,
    handleSignOutAndForget: (account: Account) => void,
    handleHideAccount: (account: Account) => void,
}) => {
    const navigate = useNavigate();

    return (
        <SimpleGrid>
            {Array.isArray(accounts) &&
                accounts.map((account, index) => {
                    return (
                        <SmallProfile
                            key={index}
                            account={account}
                            onClick={() => {
                                handleContinue(account);
                            }}
                            onSignOut={() => {
                                handleSignOut(account);
                            }}
                            onSignOutAndForget={() => {
                                handleSignOutAndForget(account);
                            }}
                            onHideAccount={() => {
                                handleHideAccount(account);
                            }}
                        />
                    );
                })}

            <AddAccountProfile
                accountsCount={Array.isArray(accounts) ? accounts.length : 0}
                onClick={() => {
                    navigate('/accounts/add');
                }}
            />
        </SimpleGrid>
    );
};

ProfilesGrid.propTypes = {
    accounts: PropTypes.array,
    handleContinue: PropTypes.func,
    handleSignOut: PropTypes.func,
    handleSignOutAndForget: PropTypes.func,
    handleHideAccount: PropTypes.func,
};

export default ProfilesGrid;

export {ProfilesGrid};
