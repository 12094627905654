import {
    useNavigate,
    useParams
} from 'react-router-dom';

import {I18n} from '@aws-amplify/core';

import {
    Box,
    Center,
    Group,
    Paper,
    Stack,
    Title
} from '@mantine/core';
import {useAccount} from "../../accounts/hooks/useAccount.ts";
import {NextButton} from "@pac/platform-ui-components";
import {
    AiOutlineArrowLeft,
    AiOutlineHome
} from "react-icons/ai";


export const FederatedUserPage = () => {

    const {account: accountId} = useParams();
    const {
        record: account,
    } = useAccount(accountId);

    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(`/accounts/${account.id}/reset-password/selection`);
    };

    const handleGoHome = () => {
        navigate('/');
    };


    return (
        <Stack my={'lg'}>
            <Title order={4} tt={'uppercase'}>{I18n.get('Reset Password for federated users')}</Title>
            <Paper p={'md'} shadow={'sm'} withBorder>
                <Center py="xl">
                    <Box>
                        Please, use your corporate sign-in provider to reset your password
                    </Box>
                </Center>
                <Group py="sm" position="center">
                    <NextButton
                        onClick={handleGoBack}
                        leftIcon={<AiOutlineArrowLeft/>}
                    >
                        Back
                    </NextButton>
                    <NextButton
                        variant="outline"
                        onClick={handleGoHome}
                        leftIcon={<AiOutlineHome/>}
                    >
                        Home
                    </NextButton>
                </Group>
            </Paper>
        </Stack>
    );
};
