import React from 'react'
import ReactDOM from 'react-dom/client'
import {BrowserRouter as Router} from 'react-router-dom';
import App from './App.tsx'

import "@fontsource/inter"
import './assets/index.scss';

import {Provider} from 'react-redux';

import './init/amplify';
import './init/app-metadata';
import './init/indexed-db';
import './init/i18n';
import './init/cloudwatch-rum';
import './init/offline-check';
import './init/sign-in-profiles';
import './init/applications';

import {store} from "./store";


ReactDOM.createRoot(document.getElementById('root')!)
        .render(
            <React.StrictMode>
                <Provider store={store}>
                    <Router>
                        <App/>
                    </Router>
                </Provider>
            </React.StrictMode>,
        )
