import {updateTermsOfUseAcceptance} from "../reducers/recordsSlice";
import {userAgreementResultSelector} from "../selectors";
import {useAuth} from "../../../hooks/useAuth";
import {useAccount} from "../../accounts/hooks/useAccount.js";
import {
    useAppDispatch,
    useAppSelector
} from "../../../hooks/useTypedReactRedux.ts";
import {RootState} from "../../../store";
import {useParams} from "react-router-dom";


export const useServiceAgreement = () => {

    const {account: accountId} = useParams();

    const {
        record: account,
    } = useAccount(accountId);

    const {profile} = useAuth({account});

    const dispatch = useAppDispatch();

    const userAgreementResult = useAppSelector((state: RootState) =>
                                                   userAgreementResultSelector(state)
    );


    const handleUpdate = () => {
        if (!profile) {
            throw new Error('Profile is required parameter');
        }
        dispatch(updateTermsOfUseAcceptance(profile));
    };

    const handleClear = () => {
        return;
    };


    return {
        userAgreementResult,
        handleUpdate,
        handleClear
    };
};