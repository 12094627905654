import {
    Route,
    Routes
} from 'react-router-dom';

import {IndexPage} from './pages/IndexPage';
import {AddPage} from './pages/AddPage';
import {IndexPage as CredentialsPage} from "../credentials/pages/IndexPage.tsx";
import {ResetPasswordLayout} from "../reset-password/layout.tsx";
import {MethodSelectionLayout} from "../method-selection/layout.tsx";
import {ChangePasswordLayout} from "../change-password/layout.tsx";
import {IndexPage as RedirectPage} from "../redirect/pages/IndexPage.tsx";
import {UserAgreementsLayout} from "../user-agreements/layout.tsx";
import {NotFoundPage as AccountNotFoundPage} from "./pages/NotFoundPage.tsx";


export const AccountsLayout = () => {
    return (
        <Routes>
            <Route path="/" element={<IndexPage/>}/>
            <Route path="add" element={<AddPage/>}/>
            <Route path="not-found/:email" element={<AccountNotFoundPage/>}/>

            <Route path=":account/credentials" element={<CredentialsPage/>}/>
            <Route path=":account/reset-password/*" element={<ResetPasswordLayout/>}/>
            <Route path=":account/method-selection/*" element={<MethodSelectionLayout/>}/>
            <Route path=":account/change-password/*" element={<ChangePasswordLayout/>}/>
            <Route path=":account/redirect" element={<RedirectPage/>}/>
            <Route path=":account/user-agreements/*" element={<UserAgreementsLayout/>}/>


        </Routes>
    );
};
