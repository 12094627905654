import BaseService from "../../../services/BaseService.ts";

const apiName = 'TenantManagementServiceAPI';
const collectionPath = '/users';

export const fetchRecords = (query: object) => {
    const baseService = new BaseService(apiName, collectionPath);
    return baseService.fetchRecords(query);
};

export const fetch = (id: string) => {
    const baseService = new BaseService(apiName, collectionPath);
    return baseService.fetch(id);
};

export const add = (payload: object) => {
    const baseService = new BaseService(apiName, collectionPath);
    return baseService.add(payload);
};

export const update = (id: string, payload: object) => {
    const baseService = new BaseService(apiName, collectionPath);
    return baseService.update(id, payload);
};

export const deleteRecord = (id: string) => {
    const baseService = new BaseService(apiName, collectionPath);
    return baseService.deleteRecord(id);
};

export const fetchUserWithLocation = async (location: string) => {
    const baseService = new BaseService(apiName, collectionPath);
    let path = location;
    let options = {};
    return await baseService.makeGetApiCall(apiName, path, options);
};