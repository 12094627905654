import {
    configureStore,
    Store
} from '@reduxjs/toolkit';

import ApplicationsReducer from '../features/applications/reducers/index.js';
import {AccountsReducer} from "../features/accounts/reducers";
import {CredentialsReducer} from "../features/credentials/reducers";
import {CustomersReducer} from "../features/customers/reducers";
import {ChangePasswordReducer} from "../features/change-password/reducers";
import {ResetPasswordReducer} from "../features/reset-password/reducers";
import {UserAgreementsReducer} from "../features/user-agreements/reducers";
import {SignInProfilesReducer} from "../features/sign-in-profiles/reducers";

export const reducers = {
    accounts: AccountsReducer,
    applications: ApplicationsReducer,
    credentials: CredentialsReducer,
    customers: CustomersReducer,
    changePassword: ChangePasswordReducer,
    resetPassword: ResetPasswordReducer,
    userAgreements: UserAgreementsReducer,
    signInProfiles: SignInProfilesReducer,
};


export const setupStore = (preloadedState: object) => {
    return configureStore({
                              reducer: reducers,
                              middleware: (getDefaultMiddleware) =>
                                  getDefaultMiddleware({
                                                           serializableCheck: false,
                                                       }),
                              preloadedState,
                          });
};

// added this middleware  to disable this error - A non-serializable value was detected in the state, in the path...
// @todo disable if better way is found
export const store: Store = configureStore({
                                               reducer: reducers,
                                               middleware: (getDefaultMiddleware) =>
                                                   getDefaultMiddleware({
                                                                            serializableCheck: false,
                                                                        }),
                                           });

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
