import {
    confirmPasswordChange,
    requestPasswordChange
} from "../reducers/recordsSlice.ts";
import {
    useAppDispatch,
    useAppSelector
} from "../../../hooks/useTypedReactRedux.ts";
import {useAccount} from "../../accounts/hooks/useAccount.ts";
import {Credentials} from "../../credentials/types.ts";
import {
    confirmResultSelector,
    requestResultSelector
} from "../selectors.ts";
import {Account} from "../../accounts/types.ts";
import {useParams} from "react-router-dom";

export const useResetPassword = () => {

    const {account: accountId} = useParams();

    const {
        record: account,
    } = useAccount(accountId);

    const dispatch = useAppDispatch();

    const requestResult = useAppSelector((state) => requestResultSelector(state));
    const confirmResult = useAppSelector((state) => confirmResultSelector(state));

    const resetPassword = async (values: Credentials) => {

        dispatch(
            requestPasswordChange({
                                      account,
                                      emailAddress: values.email?.toLowerCase() as string,
                                  })
        );

        console.log('resetPassword')
    }

    const confirmChange = async (account: Account, values: Credentials) => {
        dispatch(confirmPasswordChange({
                                           account,
                                           values
                                       }));

    }

    return {
        resetPassword,
        confirmChange,
        requestResult,
        confirmResult,
    }

}