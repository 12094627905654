import {Account} from "../../accounts/types.ts";
import {platform} from "../../../config/platform.ts";

export const setUrlWithParameters = (
    domain: string,
    redirect_uri: string,
    response_type: string,
    client_id: string,
    identity_provider: string,
    scopes: string[]
) => {
    const queryString = Object.entries({
                                           client_id: client_id,
                                           response_type: response_type,
                                           //state: Math.random(),
                                           redirect_uri: redirect_uri,
                                           identity_provider: identity_provider,
                                           scopes: scopes,
                                       })
        //.map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
                              .map(([k, v]) => `${k}=${v}`)
                              .join('&');

    const url = `https://${domain}.auth.us-west-2.amazoncognito.com/oauth2/authorize?${queryString}`;
    return url
}

export const hasIdProvider = (info: Account) => {
    if (typeof info['nextcloudIdentityProvider'] === 'undefined') {
        return false;
    }

    if (info['nextcloudIdentityProvider'] === null) {
        return false;
    }

    return true;
};

export const getIdProvider = (info: Account) => {
    if (hasIdProvider(info) === true) {
        return info['nextcloudIdentityProvider'];
    }
    return null;
};

export const getAuthorizeUrl = (loginProfile: Account) => {
    // const provider = this.getIdProvider(info);
    // return provider.url;

    let client_id = loginProfile['appClientId'];
    let domain = loginProfile['cognitoUserPoolDomain'];
    let redirect_uri = platform.login;
    let response_type = 'code';

    let identity_provider = 'Panasonic.aero';
    let scopes = [] as string[];

    if (typeof loginProfile['nextcloudIdentityProvider'] !== 'undefined') {
        identity_provider = loginProfile['nextcloudIdentityProvider'].name;
        scopes = loginProfile['nextcloudIdentityProvider'].scopes;
    }

    const queryString = Object.entries({
                                           client_id: client_id,
                                           response_type: response_type,
                                           //state: Math.random(),
                                           redirect_uri: redirect_uri,
                                           identity_provider: identity_provider,
                                           scopes: scopes,
                                       })
                              .map(([k, v]) => `${k}=${v}`)
                              .join('&');

    let url = `https://${domain}.auth.us-west-2.amazoncognito.com/oauth2/authorize?${queryString}`;
    return url;
};

