import * as PropTypes from 'prop-types';

import {Text} from '@mantine/core';
import {Account} from "../types.ts";

export const UserFullName = ({user}: { user: Account }) => {
    let fullName = '';

    let firstName = user['emailAddress'];
    let lastName = '';

    if (
        typeof user['firstName'] !== 'undefined' &&
        user['firstName'].toString().length > 0
    ) {
        firstName = user['firstName'];
    }
    if (
        typeof user['lastName'] !== 'undefined' &&
        user['lastName'].toString().length > 0
    ) {
        lastName = user['lastName'];
    }

    fullName = [firstName, lastName].join(' ');

    return <Text fw={800}>{fullName}</Text>;
};

UserFullName.propTypes = {
    user: PropTypes.object.isRequired,
};
