import {stage} from "../stage.ts";

type ConfigShape = {
    [key: string]: {
        disabled: boolean,
        appId: string,
        region: string
    }
}

const pinpoint = {
    prd: {
        disabled: true,
        appId: process.env.VITE_PINPOINT_PROJECT_ID,
        region: 'us-west-2',
    },
    test: {
        disabled: true,
        appId: process.env.VITE_PINPOINT_PROJECT_ID,
        region: 'us-west-2',
    },
    dev: {
        disabled: true,
        appId: process.env.VITE_PINPOINT_PROJECT_ID,
        region: 'us-west-2',
    },
    ide: {
        disabled: true,
        appId: process.env.VITE_PINPOINT_PROJECT_ID,
        region: 'us-west-2',
    },
} as ConfigShape;

let analytics = {
    // OPTIONAL - disable Analytics if true
    disabled: true,
    // OPTIONAL - Allow recording session events. Default is true.
    autoSessionRecord: true,

    AWSPinpoint: {
        // OPTIONAL -  Amazon Pinpoint App Client ID
        appId: pinpoint[stage].appId,
        // OPTIONAL -  Amazon service region
        region: pinpoint[stage].region,

        endpoint: {
            userAttributes: {},
        },

        // Buffer settings used for reporting analytics events.
        // OPTIONAL - The buffer size for events in number of items.
        bufferSize: 1000,

        // OPTIONAL - The interval in milliseconds to perform a buffer check and flush if necessary.
        flushInterval: 5000, // 5s

        // OPTIONAL - The number of events to be deleted from the buffer when flushed.
        flushSize: 100,

        // OPTIONAL - The limit for failed recording retries.
        resendLimit: 5,
    },
};

export default analytics;
