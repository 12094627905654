const staticAssetsBucket = {
    prd: {
        name: 'pac-idam-console-827098250628-us-west-2',
        region: 'us-west-2',
        customPrefix: {
            public: 'assets/images/',
            protected: 'assets/images/',
            private: 'assets/images/',
        },
    },
    test: {
        name: 'pac-idam-console-766605398863-us-west-2',
        region: 'us-west-2',
        customPrefix: {
            public: 'assets/images/',
            protected: 'assets/images/',
            private: 'assets/images/',
        },
    },
    dev: {
        name: 'pac-idam-console-364161771924-us-west-2',
        region: 'us-west-2',
        customPrefix: {
            public: 'assets/images/',
            protected: 'assets/images/',
            private: 'assets/images/',
        },
    },
    ide: {
        name: 'pac-idam-console-364161771924-us-west-2',
        region: 'us-west-2',
        customPrefix: {
            public: 'assets/images/',
            protected: 'assets/images/',
            private: 'assets/images/',
        },
    },
};

// overriding to allways point to dev values
let storage = {
    AWSS3: {
        bucket: staticAssetsBucket['dev'].name,
        region: staticAssetsBucket['dev'].region,
    },
    customPrefix: staticAssetsBucket['dev'].customPrefix,
};

export default storage;
