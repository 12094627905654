import {RootState} from "../../store";
import {Account} from "./types.ts";

export const accountsSelector = (state: RootState) => {
    const collection = state.accounts.records.ids.map((id: string) => {
        return state.accounts.records.byId[id];
    }) as Account[];
    return collection.filter((item) => {
        return item.isVisible === 'yes';
    });
};

export const accountsLoadingSelector = (state: RootState) => {
    return state.accounts.records.loading;
};

export const accountSelector = (state: RootState, id: string) => {
    return state.accounts.records.byId[id] as Account
};

export const accountsCountSelector = (state: RootState) => {
    return state.accounts.records.totalCount;
};

export const selectedLoginProfileSelector = (state: RootState) => {
    let keys = Object.keys(state.accounts.selection);

    if (Array.isArray(keys) && keys.length > 0) {
        return state.accounts.selection[keys[0]] as Account;
    }
};

export const accountsSelectionSelector = (state: RootState) => {
    return state.accounts.selection;
};

export const accountsSelectionLengthSelector = (state: RootState) => {
    let selection = accountsSelectionSelector(state);
    let keys = Object.keys(selection);
    return keys.length;
};
