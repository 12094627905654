import {Account} from "../features/accounts/types.ts";
import {platform} from "../config/platform.ts";

/*
@see this - https://advancedweb.hu/how-to-secure-the-cognito-login-flow-with-a-state-nonce-and-pkce/
*/

const AUTHORIZE_CACHE_KEY = 'pac-authorize-info';

const sha256 = async (str: string) => {
    return await crypto.subtle.digest('SHA-256', new TextEncoder().encode(str));
};

const generateNonce = async () => {
    const hash = await sha256(
        crypto.getRandomValues(new Uint32Array(4))
              .toString()
    );
    // https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest
    const hashArray = Array.from(new Uint8Array(hash));
    return hashArray.map((b) => b.toString(16)
                                 .padStart(2, '0'))
                    .join('');
};

const base64URLEncode = (str: string) => {

    // @ts-ignore
    return btoa(String.fromCharCode.apply(null, new Uint8Array(str)))
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=+$/, '');
};

const generateCodeChallengeParams = async (codeVerifier: string) => {
    const codeChallengeMethod = 'S256';
    const decodedChallenge = await sha256(codeVerifier)
    // @ts-ignore
    const codeChallenge = base64URLEncode(decodedChallenge);

    return {
        code_challenge_method: codeChallengeMethod,
        code_challenge: codeChallenge,
    };
};

export const authorizeUrlHelper = async (account: Account) => {
    if (!Object.prototype.hasOwnProperty.call(account, 'appClientId')) {
        throw new Error('appClientId is required property');
    }

    if (!Object.prototype.hasOwnProperty.call(account, 'cognitoUserPoolDomain')) {
        throw new Error('cognitoUserPoolDomain is required property');
    }

    const state = await generateNonce();
    const codeVerifier = await generateNonce();

    sessionStorage.setItem(
        AUTHORIZE_CACHE_KEY,
        JSON.stringify({
                           cognitoUserPoolId: account.cognitoUserPoolId,
                           appClientId: account.appClientId,
                           cognitoUserPoolDomain: account.cognitoUserPoolDomain,
                       })
    );

    sessionStorage.setItem(`codeVerifier-${state}`, codeVerifier);

    let client_id = account['appClientId'];
    let domain = account['cognitoUserPoolDomain'];
    let redirect_uri = platform.login;

    let response_type = 'code';
    //let response_type = 'token';

    let identity_provider = 'Panasonic.aero';
    let scopes = [] as string[];

    if (typeof account['nextcloudIdentityProvider'] !== 'undefined') {
        identity_provider = account['nextcloudIdentityProvider'].name;
        scopes = account['nextcloudIdentityProvider'].scopes;
    }

    let params = {
        client_id: client_id,
        response_type: response_type,
        redirect_uri: encodeURIComponent(redirect_uri),
        identity_provider: identity_provider,
        scopes: scopes.join('+'),
    };

    const useNonce = false;
    if (useNonce) {
        params = Object.assign({}, params, {state: state});
    }

    // adding code_challenge does not work
    const useCodeVerifier = false;
    if (useCodeVerifier) {
        params = Object.assign(
            {},
            params,
            generateCodeChallengeParams(codeVerifier)
        );
    }

    const queryString = Object.entries(params)
                              .map(([k, v]) => `${k}=${v}`)
                              .join('&');

    let url = `https://${domain}.auth.us-west-2.amazoncognito.com/oauth2/authorize?${queryString}`;

    // let url = `https://${domain}.auth.us-west-2.amazoncognito.com/login?${queryString}`;

    return url;
};
