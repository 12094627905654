import {
    Navigate,
    useParams
} from 'react-router-dom';

import {PlatformLoader} from "@pac/platform-ui-components";
import {useAccount} from "../../accounts/hooks/useAccount.ts";
import {useRedirectUrl} from "../../../hooks/useRedirectUrl.ts";
import {useAuth} from "../../../hooks/useAuth.ts";
import {useApplications} from "../../applications/hooks/useApplications.ts";
import {useAccounts} from "../../accounts/hooks/useAccounts.ts";
import {useEffect} from "react";
import {Account} from "../../accounts/types.ts";
import {Application} from "../../applications/types.ts";

export const IndexPage = () => {

    const {account: accountId} = useParams();

    const {
        record: account,
    } = useAccount(accountId);


    const {
        accounts,
        handleFetchAccounts: fetchAccounts
    } = useAccounts();

    useEffect(() => {
        fetchAccounts()
    }, [])

    const {
        applications,
        handleFetchApplications
    } = useApplications()

    useEffect(() => {
        handleFetchApplications()
    }, [])


    if (!Array.isArray(accounts) || accounts.length === 0) {
        return <PlatformLoader message="Loading accounts... Please wait"/>;
    }

    if (!Array.isArray(applications) || applications.length === 0) {
        return <PlatformLoader message="Loading applications... Please wait"/>;
    }

    const application = applications[0]

    if (typeof account === 'undefined' || account === null) {
        return <PlatformLoader message="Loading account... Please wait"/>;
    }

    return <CheckAndRedirect account={account} application={application}/>;

};


export const CheckAndRedirect = ({
                                     account,
                                     application
                                 }: { account: Account, application: Application }) => {

    const {
        profile,
        session
    } = useAuth({account});

    if (
        typeof session === 'undefined' ||
        session === null ||
        !session.isValid()
    ) {
        return <PlatformLoader message="Loading session... Please wait"/>;
        // return <Navigate to={`/accounts/${account.id}/credentials`} />;
    }

    if (typeof profile === 'undefined' || profile === null) {
        return <PlatformLoader message="Loading Profile... Please wait"/>;
    }

    const {email} = session.getIdToken().payload;

    // this does not work fo users with different email addresses
    const enabledCheck = false
    if (enabledCheck) {
        if (
            account.emailAddress?.toLowerCase() !== email.toLowerCase()
            // user?.attributes.email.toLowerCase()
        ) {
            return <Navigate to={`/accounts/${account.id}/credentials`}/>;
        }
    }


    if (typeof profile !== 'undefined' && profile !== null) {
        if (
            typeof profile.tc === 'undefined' ||
            profile.tc.accepted === false
        ) {
            return (
                <Navigate to={`/accounts/${account.id}/user-agreements/acceptance`}/>
            );
        }
    }

    const redirectUrl = useRedirectUrl({
                                           application,
                                           session
                                       });
    console.debug("redirect url", redirectUrl)
    window.location.assign(redirectUrl);
    return null;

}