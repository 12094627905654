import {Outlet} from 'react-router-dom';

import {
    PlatformFooter,
    PlatformHeader,
} from '@pac/platform-ui-components';

import {
    ActionIcon,
    AppShell,
    Box,
    Center,
    Container,
    Footer,
    Header,
    Paper,
    useMantineColorScheme,
} from '@mantine/core';
import PanasonicLogo from "../assets/images/panasonic-logo.png";
import {
    AiFillMoon,
    AiFillSun
} from "react-icons/ai";
import {PageFooter} from "./PageFooter.tsx";

const AppLayout = () => {

    const {
        colorScheme,
        toggleColorScheme
    } = useMantineColorScheme();
    const dark = colorScheme === 'dark';

    const ColorSchemeIcon = (
        <ActionIcon
            variant="subtle"
            color={dark ? 'yellow' : 'blue'}
            onClick={() => toggleColorScheme()}
            title="Toggle color scheme"
        >
            {dark ? <AiFillSun size={'3.5em'}/> : <AiFillMoon size={'3.5em'}/>}
        </ActionIcon>
    );


    return (
        <AppShell
            p="0px"
            navbarOffsetBreakpoint="sm"
            // navbar={null}
            header={
                <Header height={70}><PlatformHeader settingsButton={ColorSchemeIcon}/></Header>}
            footer={
                <Footer height={95} py="xs">
                    <PlatformFooter/>
                </Footer>
            }
            styles={(theme) => ({
                main: {
                    backgroundColor:
                        theme.colorScheme === 'dark'
                            ? theme.colors.dark[8]
                            : theme.colors.gray[2],
                },
            })}
        >

            <Container>
                <Paper p={'lg'}>
                    <Center my={'lg'} py="xl" className="border-bottom-primary">
                        <img
                            src={PanasonicLogo}
                            alt="Panasonic Avionics"
                            className="logo"
                        />
                    </Center>

                    <Box p="s">
                        <Outlet/>
                    </Box>

                    <PageFooter/>
                </Paper>
            </Container>

        </AppShell>
    );
};

export default AppLayout;
