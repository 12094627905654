import {useEffect} from 'react';
import {useSignInProfiles} from "../hooks/useSignInProfiles.ts";
import {
    ActionIcon,
    Badge,
    Center,
    Group,
    Stack,
    Table,
    Text,
    Title
} from "@mantine/core";
import {useNavigate} from "react-router-dom";
import {useSignInProfile} from "../hooks/useSignInProfile.ts";
import {
    AiOutlineCheck,
    AiOutlineDelete,
    AiOutlineSelect
} from "react-icons/ai";
import {NextAddButton} from '@pac/platform-ui-components';
import {I18n} from 'aws-amplify';

export const IndexPage = () => {

    const navigate = useNavigate();

    const {
        signInProfiles,
        handleFetchSignInProfiles,
    } = useSignInProfiles();

    const {
        handleDeleteSignInProfile,
        handleMakeActive
    } = useSignInProfile();

    useEffect(() => {
        handleFetchSignInProfiles();
    }, []);


    const rows = () => {
        return signInProfiles.map((option) => {

            return (
                <tr key={option.id}>
                    <td>{option.id}</td>
                    <td><Center>{option.isDefault ? <AiOutlineCheck/> : 'no'}</Center></td>
                    <td>
                        <Center>
                            {option.isActive ?
                                <Badge color={'green'}>{I18n.get('Active')}</Badge>
                                :
                                <ActionIcon onClick={() => handleMakeActive(option.id as string)}><AiOutlineSelect
                                    title={'Make Active'}/></ActionIcon>}
                        </Center>
                    </td>
                    <td><Center>{option.language}</Center></td>
                    <td><Center>{option.showReturnTo ? 'yes' : 'no'}</Center></td>
                    <td><Center>{option.passwordAndSso ? 'yes' : 'no'}</Center></td>
                    <td><Center>{option.verbosity}</Center></td>
                    <td>
                        {!option.isDefault ? <ActionIcon
                                onClick={() => {
                                    handleDeleteSignInProfile(option.id as string)
                                    handleFetchSignInProfiles();
                                }}><AiOutlineDelete/></ActionIcon> :
                            <ActionIcon disabled={true}><AiOutlineDelete/></ActionIcon>}
                    </td>
                </tr>
            );
        });
    };


    return (
        <Stack>
            <Group position={'apart'}>
                <Title order={3}>{I18n.get('Sign-in Profiles')}</Title>
                <NextAddButton onClick={() => navigate('add')}>{I18n.get('Add Profile')}</NextAddButton>
            </Group>
            <Table striped horizontalSpacing="sm" verticalSpacing="lg">
                <thead>
                <tr>
                    <th>ID</th>
                    <th><Center><Text fz={'small'}>{I18n.get('Default Profile')}</Text></Center></th>
                    <th><Center><Text fz={'small'}>{I18n.get('Active')}</Text></Center></th>
                    <th><Center><Text fz={'small'}>{I18n.get('Language')}</Text></Center></th>
                    <th><Center><Text fz={'small'}>{I18n.get('Show Return To')}</Text></Center></th>
                    <th><Center><Text fz={'small'}>{I18n.get('Method Selection')}</Text></Center></th>
                    <th><Center><Text fz={'small'}>{I18n.get('Verbosity')}</Text></Center></th>
                    <th><Center><Text fz={'small'}>{I18n.get('Delete')}</Text></Center></th>
                </tr>
                </thead>
                <tbody>
                {rows()}
                </tbody>
            </Table>
        </Stack>
    );


};
