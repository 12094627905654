console.debug('Initially ' + (window.navigator.onLine ? 'on' : 'off') + 'line');

window.addEventListener('online', () => {
    console.debug('Became online');
    window.location.href = '/';
});

window.addEventListener('offline', () => {
    console.debug('Became offline');
    window.location.href = '/offline';
});
