import {combineReducers} from 'redux';

import recordsReducer from './recordsSlice';


export const {} = recordsReducer;

export const UserAgreementsReducer = combineReducers({
                                                         records: recordsReducer,
                                                     });

