import {
    activeSignInProfileSelector,
    signInProfilesSelector
} from "../selectors";
import {
    deleteAllSignInProfiles,
    fetchSignInProfiles
} from "../reducers/recordsSlice";
import {
    useAppDispatch,
    useAppSelector
} from "../../../hooks/useTypedReactRedux.ts";
import {RootState} from "../../../store";

export const useSignInProfiles = () => {
    const dispatch = useAppDispatch();

    const signInProfiles = useAppSelector((state: RootState) => signInProfilesSelector(state));

    const activeProfile = useAppSelector((state: RootState) =>
                                             activeSignInProfileSelector(state)
    );

    const handleFetchSignInProfiles = () => {
        dispatch(fetchSignInProfiles());
    };


    const handleDeleteAllSignInProfiles = () => {
        dispatch(deleteAllSignInProfiles());
    };

    return {
        signInProfiles,
        activeProfile,
        handleFetchSignInProfiles,
        handleDeleteAllSignInProfiles
    };
};