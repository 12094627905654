import {Cache} from 'aws-amplify';

import {notifySuccess} from "../../../components/ServiceResponseNotifications";
import config from "../../../config";
import {Application} from "../types.ts";
import {getVerbosityLevel} from "../../sign-in-profiles/services/SignInProfilesService.ts";
import {VerbosityLevel} from "../../sign-in-profiles/types.ts";

const key = 'pac-applications';

export const isAllowedHostname = (hostname: string) => {
    let out = false;

    config.allowedReturnToDomains.forEach((domainName) => {
        if (hostname.indexOf(domainName) !== -1) {
            out = true;
        }
    });

    return out;
};

export const fetchRecords = async () => {

    const items = Cache.getItem(key, {
        callback: () => {
            return [];
        },
    }) as Application[];

    return items

};

export const fetch = async (id: string) => {
    const items = Cache.getItem(key, {
        callback: () => {
            return [];
        },
    });

    return items.find((a: Application) => a.id === id);
};

export const add = async (payload: Application) => {

    const verbosity = await getVerbosityLevel()

    if (!isAllowedHostname(payload.hostname as string)) {
        throw new Error('Operation is not allowed');
    }

    payload.createdAt = new Date().toISOString();

    const applications = Cache.getItem(key, {
        callback: () => {
            return [];
        },
    }) as Application[];

    const found = applications.find((a: Application) => a.host === payload.host);
    if (found) {
        return found;
    } else {
        applications.push(payload);
        Cache.setItem(key, applications);
        verbosity === VerbosityLevel.MEDIUM && notifySuccess('Application added');
        return payload;
    }

};

export const update = async (_: string, record: Application) => {

    const verbosity = await getVerbosityLevel()

    record.createdAt = new Date().toISOString();

    const accounts = Cache.getItem(key, {
        callback: () => {
            return [];
        },
    });

    const index = accounts.findIndex((a: Application) => a.id === record.id);
    accounts[index] = record;

    Cache.setItem(key, accounts);
    verbosity === VerbosityLevel.MEDIUM && notifySuccess('Application updated');
    return record;
};

export const deleteRecord = async (id: string) => {

    const verbosity = await getVerbosityLevel()

    const accounts = Cache.getItem(key, {
        callback: () => {
            return [];
        },
    });

    const filtered = accounts.filter((item: Application) => item.id != id);
    Cache.setItem(key, filtered);
    verbosity === VerbosityLevel.MEDIUM && notifySuccess('Application deleted');
    return id
};

export const deleteAllRecords = async () => {

    const verbosity = await getVerbosityLevel()

    const accounts = Cache.getItem(key, {
        callback: () => {
            return [];
        },
    });

    const filtered = accounts.filter((item: Application) => item.isDefault === false);
    Cache.setItem(key, filtered);

    verbosity === VerbosityLevel.MEDIUM && notifySuccess('All applications deleted');
};

export const swap = async () => {
    // const applications = await fetchRecords();
    const promises = [] as any[];
    await Promise.all(promises);
    return fetchRecords();
};
