export default {
    Home: 'Начало',
    'Sign In': 'Вход',
    'Sign Out': 'Изход',
    'Signing In': 'Започвам нова сесия',
    'Signing Out': 'Прекратявам сесията',
    'Signed In with': 'Активна сесия с емайл адрес ',
    'to continue to': 'към',
    Next: 'Продължи',
    'get verification code': 'изискай код за проверка',
    Working: 'Работя',
    'Please wait': 'Моля изчакайте',
    'Select Sign In Method': 'Избери начин за вход',
    Continue: 'Продължи',
    'Choose an account': 'Избери акаунт',
    'Add account': 'Добави акаунт',
    'Continue with SSO': 'Влез със ССО',
    'Continue with password': 'Влез с парола',
    'You have enabled Sign-In using both password and Single Sign-On (SSO)':
        'Вход с парола и ССО са включени',
    'Please make a selection below or change sign-in options':
        'Избери един начин за вход или промени настройките',
    Name: 'Име',
    'Email Address': 'Потребителски Емайл Адрес',
    Password: 'Парола',
    Preferences: 'Настройки',
    'Forgotten password': 'Забравена парола',
    Language: 'Език',
    Submit: 'Изпрати',
    Cancel: 'Откажи',
    Update: 'Потвърди',
    Delete: 'Изтрий',
    or: 'или',
    'Terms Of Service': 'Условия за ползуване',
    'Privacy Policy': 'Лични данни',
    'Contact Us': 'За контакти',
    'Pick an account': 'Избери акоунт',
    'Return to': 'Върни се към',
    'Use another account': 'Избери друг акоунт',
    About: 'За нас',
    Active: 'Активен',
    'Sign-in Profiles': 'Логин профили',
    'Add Profile': 'Добави логин профил',
    'Default Profile': 'Профил по подразбиране',
    'Show Return To': 'Покажи върни се към',
    'Method Selection': 'Избор на начин за вход',
};
