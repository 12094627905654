import {IconContext} from 'react-icons'
import {FaUser} from 'react-icons/fa'

import {Avatar} from '@mantine/core'


// const defaultLogoSize = "70px"

interface User {
    firstName?: string;
    lastName?: string;
    photoUrl?: string;
}

// interface ResizeImageOptions {
//     width: string;
//     height: string;
// }


// const getResizedImageUrl = (user: User, options: ResizeImageOptions) => {
//     const {
//         width,
//         height
//     } = options
//
//     const photoUrl = user['photoUrl']
//
//     if (typeof photoUrl !== 'undefined') {
//         const extension = photoUrl.substr(-3)
//
//
//         if (extension === 'svg') {
//             return photoUrl
//         }
//     }
//
//
//     const request = {
//         bucket: config.staticAssets.platform.imagesBucket,
//         key: 'images/' + photoUrl,
//         edits: {
//             resize: {
//                 width: width,
//                 height: height,
//                 fit: 'cover',
//             },
//         },
//     }
//
//     const str = JSON.stringify(request)
//     const encoded = window.btoa(str)
//
//     return [config.staticAssets.platform.imagesDistributionUrl, encoded].join(
//         '/'
//     )
// }


const UserThumbnail = ({
                           user,
                           width = '50px',
                           height = '50px'
                       }: {
    user: User,
    width: string,
    height: string,
}) => {

    const userFullName = [user['firstName'], user['lastName']].join(' ')

    // const imageUrl = getResizedImageUrl(user, {
    //     width: width,
    //     height: height,
    // })

    const imageUrl = user.photoUrl

    const style = {
        width: `${width}px`,
        height: `${height}px`,
        margin: '0.5em',
    }

    return (
        <Avatar
            alt={userFullName}
            src={imageUrl}
            style={style}
            radius="xl"
        />
    )

}

const UserAvatarIconPlaceholder = () => {


    const iconContext = {
        style: {
            width: `50px`,
            height: `50px`,
            margin: '0.5em',
            padding: '0.2em',
            border: 'solid 1px',
            borderRadius: '60%',
        },
    }

    return (
        <IconContext.Provider value={iconContext}>
            <FaUser/>
        </IconContext.Provider>
    )

}


export const UserAvatar = ({
                               user,
                               width = '50px',
                               height = '50px',
                           }: {
    user: User,
    width?: string,
    height?: string,
}) => {

    if (
        typeof user['photoUrl'] !== 'undefined' &&
        user['photoUrl'].toString().length > 0
    ) {
        return <UserThumbnail user={user} width={width} height={height}/>
    } else {
        return <UserAvatarIconPlaceholder/>
    }
}
