import {useAccount} from "../../accounts/hooks/useAccount.ts";
import {
    useAppDispatch,
    useAppSelector
} from "../../../hooks/useTypedReactRedux.ts";
import {Credentials} from "../types.ts";
import {forcePasswordChangeSelector} from "../selectors.ts";
import {signIn} from "../reducers/recordsSlice.ts";
import {useParams} from "react-router-dom";

export const useCredentials = () => {

    const {account: accountId} = useParams();

    const {
        record: account,
    } = useAccount(accountId);

    const dispatch = useAppDispatch();

    const forcePasswordChange = useAppSelector((state) =>
                                                   forcePasswordChangeSelector(state)
    );


    const handleSignIn = (values: Credentials) => {

        dispatch(
            signIn({
                       account: account,
                       username: account['emailAddress'] as string,
                       password: values.password as string,
                   })
        );

    }

    return {
        handleSignIn,
        forcePasswordChange,
    }
}