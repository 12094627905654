import {
    createAsyncThunk,
    createSlice
} from '@reduxjs/toolkit';

import {
    add,
    deleteAllRecords,
    deleteRecord,
    fetch,
    fetchRecords,
    update,
} from '../services/SignInProfilesService.ts';

import {
    deleteAllCase,
    deleteOneCase,
    fetchManyCase,
    fetchOneCase,
    saveOneCase,
    updateOneCase,
} from '../../../store/RecordsSliceCaseHelpers';
import {SignInProfile} from "../types.ts";


const entityKey = 'signInProfiles';

const initialState = {
    byId: {},
    ids: [],
    totalCount: 0,
    loading: false,
    error: '',
} as {
    byId: { [key: string]: any },
    ids: string[],
    totalCount: number,
    loading: boolean,
    error: string,
};


// genenrates pending, fulfilled and rejected
export const fetchSignInProfiles = createAsyncThunk(
    `${entityKey}/fetchRecords`,
    () => {
        return fetchRecords()
            .then((response) => {
                return response.map((record) => {
                    return record;
                });
            });
    }
);

export const fetchSignInProfile = createAsyncThunk(
    `${entityKey}/fetch`,
    (id: string) => {
        return fetch(id)
            .then((response) => response);
    }
);

export const saveSignInProfile = createAsyncThunk(
    `${entityKey}/add`,
    (payload: SignInProfile) => {
        return add(payload)
            .then((response) => response);
    }
);

export const updateSignInProfile = createAsyncThunk(
    `${entityKey}/update`,
    ({
         id,
         record
     }: {
        id: string,
        record: SignInProfile
    }) => {
        return update(id, record)
            .then(() => record);
    }
);

export const deleteSignInProfile = createAsyncThunk(
    `${entityKey}/deleteRecord`,
    (id: string) => {
        return deleteRecord(id)
            .then(() => id);
    }
);

export const deleteAllSignInProfiles = createAsyncThunk(
    `${entityKey}/deleteAllRecords`,
    () => {
        return deleteAllRecords()
            .then((response) => response);
    }
);

const applicationsSlice = createSlice({
                                          name: entityKey,
                                          initialState,
                                          reducers: {},
                                          extraReducers: (builder) => {
                                              // FETCH MANY
                                              fetchManyCase(builder, fetchSignInProfiles, entityKey);

                                              // FETCH ONE
                                              fetchOneCase(builder, fetchSignInProfile);

                                              // SAVE ONE
                                              saveOneCase(builder, saveSignInProfile);

                                              // UPDATE ONE
                                              updateOneCase(builder, updateSignInProfile);

                                              // DELETE ONE
                                              deleteOneCase(builder, deleteSignInProfile);

                                              // DELETE ALL
                                              deleteAllCase(builder, deleteAllSignInProfiles);
                                          },
                                      });

export default applicationsSlice.reducer;
