import {accountSelector} from '../selectors';
import {RootState} from "../../../store";
import {useAppSelector} from "../../../hooks/useTypedReactRedux.ts";

export const useAccount = (accountId?: string) => {

    const record = useAppSelector((state: RootState) => accountSelector(state, accountId ? accountId : ''));

    return {
        record
    };


};
