import {Cache} from 'aws-amplify';
import config from "../config";


const METADATA_LOCAL_STORAGE_KEY = 'pac-app-metadata';
const METADATA_LOCAL_STORAGE_VALUE = {
    version: 1,
};

const LATEST_APP_VERSION = config.about.latestVersion;

const metadata = Cache.getItem(METADATA_LOCAL_STORAGE_KEY, {
    callback: () => {
        return METADATA_LOCAL_STORAGE_VALUE;
    },
});

if (metadata.version < LATEST_APP_VERSION) {
    // if (window.location.pathname !== '/about') {
    //     window.location.href = '/about';
    // }

    // clear
    Cache.clear();
    // update version
    metadata.version = LATEST_APP_VERSION;
    // store
    Cache.setItem(METADATA_LOCAL_STORAGE_KEY, metadata);
}
