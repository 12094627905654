import PropTypes from 'prop-types';
import {
    Formik,
    FormikProps
} from 'formik';

import {FormComponent} from './FormComponent.js';

import ValidationSchema from './ValidationSchema';

import {
    DisplayFormikState,
    FormWithActionBar
} from '@pac/platform-ui-components';
import {UserAgreement} from "../types.ts";

const RecordForm = ({
                        record,
                        handleSubmit
                    }: {
    record?: UserAgreement,
    handleSubmit: (values: UserAgreement) => void
}) => {

    const onSubmit = (values: UserAgreement) => {
        return handleSubmit(values);
    };


    const renderForm = (props: FormikProps<UserAgreement>) => {
        return (
            <FormWithActionBar handleSubmit={props.handleSubmit}>
                <FormComponent {...props} />
                {false && <DisplayFormikState {...props} />}
            </FormWithActionBar>
        );
    };

    let initialValues = {} as UserAgreement;

    if (record) {
        initialValues = record;
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={ValidationSchema}
            onSubmit={onSubmit}
        >
            {renderForm}
        </Formik>
    );
};

export default RecordForm;

RecordForm.propTypes = {
    record: PropTypes.object,
    handleSubmit: PropTypes.func,
};
