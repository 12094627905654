import PropTypes from 'prop-types';

import {Formik,} from 'formik';
import {ValidationSchema} from "./CredentialsFormValidationSchema.ts";
import {Credentials} from "../types.ts";
import {FormComponent} from "./FormComponent.tsx";
import {
    ActionBar,
    DisplayFormikState,
    FormWithActionBar,
    NextButton,
    NextSubmitButton
} from "@pac/platform-ui-components";
import {useNavigate} from "react-router-dom";
import {AiOutlineClose} from "react-icons/ai";


export const CredentialsForm = ({
                                    emailAddress,
                                    handleSubmit
                                }: {
    emailAddress: string,
    handleSubmit: (values: Credentials) => void
}) => {

    const navigate = useNavigate()

    let initialValues = {
        email: emailAddress ? emailAddress : '',
        password: '',
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={ValidationSchema}
            onSubmit={handleSubmit}
        >
            {(props) => (
                <>
                    <FormWithActionBar
                        handleSubmit={props.handleSubmit}
                        actionBar={<ActionBar primaryAction={<NextSubmitButton/>}
                                              secondaryAction={<NextButton variant={'outline'}
                                                                           leftIcon={<AiOutlineClose/>}
                                                                           onClick={() => navigate('/')}>Cancel</NextButton>}/>}
                    >
                        <FormComponent {...props} />
                        {false && <DisplayFormikState {...props} />}
                    </FormWithActionBar>
                </>
            )}
        </Formik>
    );
};

CredentialsForm.propTypes = {
    handleSubmit: PropTypes.func,
    emailAddress: PropTypes.string,
};
