import {useEffect} from 'react'
import {
    useNavigate,
    useSearchParams
} from "react-router-dom";

import {
    Button,
    Center,
    Stack,
    Title
} from "@mantine/core";

import {Cache} from 'aws-amplify';

import {accountsSelector} from '../../accounts/selectors';
import {fetchAccounts} from "../../accounts/reducers/recordsSlice";
import {Auth} from '@aws-amplify/auth';
import returnToDomainNames from "../../../config/return-to-domain-names";
import {
    useAppDispatch,
    useAppSelector
} from "../../../hooks/useTypedReactRedux.ts";
import {RootState} from "../../../store";


export const AuthorizePage = () => {

    const navigate = useNavigate()
    const dispatch = useAppDispatch();

    const [params] = useSearchParams();

    const redirectUrl = params.get('redirect_uri')
    const responseType = params.get('response_type')
    const clientId = params.get('client_id')
    const identityProvider = params.get('identity_provider')
    const scope = params.get('scope')
    const state = params.get('state')

    Cache.setItem('authorization', {
        redirectUrl,
        responseType,
        clientId,
        identityProvider,
        scope,
        state
    })

    const accounts = useAppSelector((state: RootState) => accountsSelector(state));

    useEffect(() => {
        dispatch(fetchAccounts());
    }, []);


    if (accounts.length === 0) {
        return (<Center>
            <Stack>
                <Title my={'xl'}>No accounts found. Please, sign in again!</Title>
                <Center my={'xl'}>
                    <Button onClick={() => navigate('/')}>Sign In</Button>
                </Center>
            </Stack>
        </Center>)
    }


    const account = accounts[0]

    // Configure Amplify Auth with the first account
    Auth.configure({
                       userPoolId: account.cognitoUserPoolId,
                       userPoolWebClientId: account.appClientId,
                   });


    Auth.currentSession()
        .then((session) => {


            const idToken = session.getIdToken()
                                   .getJwtToken()
            const accessToken = session.getAccessToken()
                                       .getJwtToken()

            // console.debug("SESSION", session)

            // console.debug("ID TOKEN", idToken.jwtToken)
            // console.debug("ACCESS TOKEN", accessToken.jwtToken)

            if (idToken && accessToken) {

                const allowedRedirectUrls = returnToDomainNames
                // console.debug("ALLOWED REDIRECT URLS", allowedRedirectUrls)

                const check = false
                if (check && !allowedRedirectUrls.includes(redirectUrl as string)) {
                    throw new Error(`Invalid redirect url ${redirectUrl}`)
                }

                const returnUrl = `${redirectUrl}#id_token=${idToken}&access_token=${accessToken}&token_type=Bearer&expires_in=3600&state=${state}`
                // console.debug("RETURN URL", returnUrl)
                Cache.removeItem('authorization')
                window.location.assign(returnUrl)

            }
        })


    return (<Center>
        <Stack>
            <Title my={'xl'}>Session has expired. Please, sign in again!</Title>
            <Center my={'xl'}>
                <Button onClick={() => navigate('/')}>Sign In</Button>
            </Center>
        </Stack>
    </Center>)


}
