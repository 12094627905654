const returnToDomainNames = [
    'panasonic.aero',
    'panasonic.com',
    'nextcloud.aero',
    'amazonaws.com',
    'sandbox.my.site.com',
    'portalworks.io',
    'localhost',
];

export default returnToDomainNames;
