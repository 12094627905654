import {
    deleteAccount,
    deleteAllAccounts,
    fetchAccounts,
    saveAccount,
    searchAccounts,
    updateAccount
} from "../reducers/recordsSlice";
import {accountsSelector} from "../selectors";
import {clearSelection} from "../reducers";
import {
    useAppDispatch,
    useAppSelector
} from "../../../hooks/useTypedReactRedux.ts";
import {RootState} from "../../../store";
import {Account} from "../types.ts";

export const useAccounts = () => {
    const dispatch = useAppDispatch();

    const accounts = useAppSelector((state: RootState) => accountsSelector(state));

    const handleFetchAccounts = () => {
        dispatch(fetchAccounts());
    }

    const handleSearchAccounts = (email: string) => {
        dispatch(searchAccounts(email));
    }

    const handleAddAccount = (payload: Account) => {
        dispatch(saveAccount(payload));
    }

    const handleUpdateAccount = (id: string, record: Account) => {
        dispatch(updateAccount({
                                   id,
                                   record
                               }));
    }

    const handleDeleteAccount = (id: string) => {
        dispatch(deleteAccount(id));
    }

    const handleDeleteAllAccounts = () => {
        dispatch(deleteAllAccounts());
    }

    const handleClearSelection = () => {
        dispatch(clearSelection());
    }

    return {
        accounts,
        handleFetchAccounts,
        handleSearchAccounts,
        handleUpdateAccount,
        handleAddAccount,
        handleDeleteAccount,
        handleDeleteAllAccounts,
        handleClearSelection
    }
}