import {
    Route,
    Routes
} from 'react-router-dom';
import {IndexPage} from "./pages/IndexPage.tsx";

export const ChangePasswordLayout = () => {
    return (
        <Routes>
            <Route path="/" element={<IndexPage/>}/>
        </Routes>
    );
};
