import {Auth} from '@aws-amplify/auth';
import {stage} from "../stage.ts";

type ConfigShape = {
    [key: string]: {
        endpoint: string,
        region: string,
        apiKey?: string,
    }
}


const tenantManagementServiceAPI = {
    prd: {
        endpoint: 'https://uhdg0ahs3d.execute-api.us-west-2.amazonaws.com/dev',
        region: 'us-west-2',
        apiKey: 'pac-idam-login-T2BhDhaPwcaGOtmTWupbKccP6F5Fzp254w9g59Ib',
    },
    test: {
        endpoint: 'https://hh3iqenpr2.execute-api.us-west-2.amazonaws.com/dev',
        region: 'us-west-2',
        apiKey: 'pac-idam-login-XqtS4F8m4P3yFNjMkKj3hkNARHgp2lZs0as9xvD1',
    },
    dev: {
        endpoint: 'https://e4f81ajvp6.execute-api.us-west-2.amazonaws.com/dev',
        region: 'us-west-2',
        apiKey: 'pac-idam-login-LoqV8W3o2O1pBFuNuFs5maOWLFrv3qFn7ma3bpQ4',
    },
    ide: {
        endpoint: 'https://e4f81ajvp6.execute-api.us-west-2.amazonaws.com/dev',
        region: 'us-west-2',
        apiKey: 'pac-idam-login-LoqV8W3o2O1pBFuNuFs5maOWLFrv3qFn7ma3bpQ4',
    },
} as ConfigShape;

const getCustomHeader = async () => {
    try {
        const token = (await Auth.currentSession())
            .getIdToken()
            .getJwtToken();
        return {
            Authorization: `${token}`,
            'x-api-key': tenantManagementServiceAPI[stage].apiKey,
        };
    } catch (e) {
        return {
            'x-api-key': tenantManagementServiceAPI[stage].apiKey,
        };
    }
};


let API = {
    endpoints: [
        {
            name: 'TenantManagementServiceAPI',
            endpoint: tenantManagementServiceAPI[stage].endpoint,
            region: tenantManagementServiceAPI[stage].region,
            custom_header: getCustomHeader,
        },
    ],
};

export default API;
