import PropTypes from 'prop-types';

import {
    ErrorMessage,
    Field
} from 'formik';


import {
    Box,
    Grid,
    Group,
    ScrollArea,
    Tabs,
    Text
} from '@mantine/core';


import SSOCustomerAgreement from '../components/SSOCustomerAgreement';
import {TermsOfUseDocument} from '../components/TermsOfUseDocument';
import {PacFormikCheckbox} from "@pac/platform-ui-components";

const agreements = [
    {
        id: 'sso',
        title: 'SSO (Single Sign On) Agreement',
        path: '/some-path',
        element: <SSOCustomerAgreement/>,
    },
    {
        id: 'terms',
        title: 'Terms of Use',
        path: '/some-path',
        element: <TermsOfUseDocument/>,
    },
];

const sections = [
    {
        id: 'sso',
        title: 'SSO (Single Sign On) Agreement',
        component: <SSOCustomerAgreement/>,
        defaultExpanded: true,
    },
    {
        id: 'terms',
        title: 'Terms Of Use',
        component: <TermsOfUseDocument/>,
        defaultExpanded: true,
    },
];


const FormLineItem = ({
                          id,
                          title
                      }: {
    id: string,
    title: string
}) => {
    return (
        <Group my={'sm'}>
            <Box px="sm">
                <Field id={id} type="checkbox" name={id}/>
            </Box>
            <label htmlFor={id}>I Agree to the {title}</label>
            <Text color={'red'}><ErrorMessage name={id}/></Text>
        </Group>
    );
};


export const FormComponent = () => (
    <Grid grow>
        <Grid.Col span={12}>
            <Tabs defaultValue={sections[0].title}>
                <Tabs.List>
                    {sections.map((item) => {
                                      return (
                                          <Tabs.Tab value={item.title} key={item.id}>
                                              {item.title}
                                          </Tabs.Tab>
                                      );
                                  }
                    )}
                </Tabs.List>
                {sections.map((item) => {
                                  return (
                                      <Tabs.Panel value={item.title} key={item.id}>
                                          <ScrollArea h={250} my={'lg'}>
                                              {item.component}
                                          </ScrollArea>
                                      </Tabs.Panel>
                                  );
                              }
                )}
            </Tabs>
        </Grid.Col>

        <Grid.Col>
            {agreements.map(agreement => {
                return (
                    <Field
                        my={'lg'}
                        key={agreement.id}
                        name={agreement.id}
                        label={`I agree to ${agreement.title}`}
                        component={PacFormikCheckbox}
                    />
                );
            })}

        </Grid.Col>
    </Grid>
);

FormComponent.propTypes = {
    handleSubmit: PropTypes.func,
    isSubmitting: PropTypes.bool,
};

FormLineItem.propTypes = {
    id: PropTypes.string,
    title: PropTypes.string,
    element: PropTypes.object,
};
