import PropTypes from 'prop-types';
import {
    Formik,
    FormikProps
} from 'formik';

import {FormComponent} from './FormComponent';

import {ValidationSchema} from './ValidationSchema.ts';
import {
    DisplayFormikState,
    FormWithActionBar
} from "@pac/platform-ui-components";
import {Credentials} from "../../credentials/types.ts";


export const ChangePasswordForm = ({
                                       record,
                                       handleSubmit
                                   }: {
    record: Credentials,
    handleSubmit: (values: Credentials) => void
}) => {

    const onSubmit = (values: Credentials) => {
        return handleSubmit(values);
    };

    const renderForm = (props: FormikProps<Credentials>) => {
        return (
            <FormWithActionBar handleSubmit={props.handleSubmit}>
                <FormComponent {...props} />
                {false && <DisplayFormikState {...props} />}
            </FormWithActionBar>
        );
    };

    let initialValues = {
        new_password: '',
        confirm_password: '',
        ts: false,
    } as Credentials;

    if (record) {
        initialValues = record;
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={ValidationSchema}
            onSubmit={onSubmit}
        >
            {renderForm}
        </Formik>
    );
};


ChangePasswordForm.propTypes = {
    record: PropTypes.object,
    handleSubmit: PropTypes.func,
};
