import en from './en';
import bg from './bg';
import hi from './hi';
import ja from './ja';

export default {
    en: en,
    bg: bg,
    hi: hi,
    ja: ja,
};
