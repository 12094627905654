import {Center} from '@mantine/core'

export const TermsOfUseDocument = () => (
    <Center>
        <div className="pa-rich-text-block_content-container">
            <p>TERMS OF USE</p>
            <p>The following are terms of use (“Terms of Use”) for www.panasonic.aero, the website of Panasonic Avionics
                Corporation (“Panasonic”). The Terms of Use incorporate Panasonic’s Privacy Policy as though fully
                written
                herein. These Terms of Use constitute a legally binding agreement shall govern your access to the
                website.
                Your use of this website constitutes your affirmative acceptance of these Terms of Use.</p>
            <p>COPYRIGHT</p>
            <p>All content included on this website such as text, graphics, logos, button icons, images, audio clips,
                digital downloads, and data compilations is the property of Panasonic or its content suppliers and is
                protected by United States and/or international copyright laws. All software used on this website is the
                property of Panasonic and/or its software suppliers and is protected by United States and international
                copyright laws.</p>
            <p>TRADEMARKS</p>
            <p>Panasonic’s graphics, logos, page headers, button icons, scripts, and service names are trademarks or
                trade
                dress of Panasonic and/or its affiliated companies in the U.S. and/or other countries. Such trademarks
                and
                trade dress may not be used in connection with any product or service that is not provided by Panasonic,
                or
                in any manner that is likely to cause confusion among customers or that disparages or discredits
                Panasonic
                or its affiliated companies. All other trademarks not owned by Panasonic or its affiliated companies
                that
                appear on this website are the property of their respective owners, who may or may not be associated
                with
                Panasonic.</p>
            <p>LICENSE AND WEBSITE ACCESS</p>
            <p>Based exclusively on your acceptance of these Terms of Use, Panasonic hereby grants you a limited license
                to
                access and make personal use of this website. However, you may not download (other than page caching) or
                modify the website, or any portion of it, except with express written consent of Panasonic. This license
                expressly excludes any resale or commercial use of this website or its contents; any collection and use
                of
                any product listings or descriptions; any derivative use of this website or its contents; or any use of
                data
                mining, robots, or similar data gathering and extraction tools. This website, nor any portion of this
                website, may be reproduced, duplicated, copied, sold, resold, or otherwise exploited for any commercial
                purpose whatsoever, without express written consent of Panasonic. You may not frame or utilize framing
                techniques to enclose any trademark, logo, or other proprietary information (including images, text,
                page
                layout, or form) of Panasonic without Panasonic’s express written consent. You may not use any meta tags
                or
                any other “hidden text” utilizing Panasonic’s name or trademarks without the express written consent of
                Panasonic. Any unauthorized use terminates the permission or license granted by Panasonic and may expose
                you
                to associated liabilities.</p>
            <p>REVIEWS, COMMENTS, COMMUNICATIONS, AND OTHER CONTENT</p>
            <p>Panasonic may, in its sole discretion, invite you to post reviews, comments, photos, and other content
                and
                submit suggestions, ideas, comments, questions, or other information, provided however, that you are
                prohibited from posting any content that is illegal, obscene, threatening, defamatory, invasive of
                privacy,
                infringing of intellectual property rights, or otherwise objectionable or injurious to third parties. or
                consists of, or contains, software viruses, political campaigning, commercial solicitation, chain
                letters,
                mass mailings, or any form of “spam.” You may not use a false e-mail address, impersonate any person or
                entity, or otherwise mislead as to the origin of a comment or other content.</p>
            <p>If you do post content or submit material, and unless we indicate otherwise, you grant Panasonic a
                nonexclusive, royalty-free, perpetual, irrevocable, and fully sublicensable right to use, reproduce,
                modify,
                adapt, publish, translate, create derivative works from, distribute, and display such content throughout
                the
                world in any media. You grant Panasonic and its sublicensees the right to use the name that you submit
                in
                connection with such content if they choose. You represent and warrant: that you own or otherwise
                control
                all of the rights to the content that you post; that the content is accurate; that use of the content
                you
                supply does not violate this policy and will not cause injury to any person or entity; and that you will
                indemnify Panasonic and its affiliated companies for all claims resulting from content you supply.</p>
            <p>Panasonic has the right, but not the obligation, to monitor and edit or remove any activity or content.
                Panasonic takes no responsibility and assumes no liability for any content posted by you or any third
                party.</p>
            <p>SUBMISSION OF SUGGESTIONS OR IDEAS</p>
            <p>Panasonic is actively engaged in R&amp;D, and possesses a variety of ideas and technical information at
                any
                given time. In order to avoid restrictions on Panasonic’s unique R&amp;D activities and avoid confusion
                related to intellectual property rights, including but not limited to, patents, copyright, trademark and
                the
                like, Panasonic will not entertain unsolicited technical proposals related to products, or otherwise,
                from
                you or third parties.</p>
            <p>Notwithstanding the foregoing, unless there is a particular agreement between the parties, Panasonic will
                not
                be required to review or report upon the proposals we receive from parties and will not be required to
                maintain the confidentiality of proposal details or make payments.</p>
            <p>DISCLAIMERS</p>
            <p>THIS WEBSITE AND ALL INFORMATION, CONTENT, AND MATERIALS INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU
                THROUGH THIS WEBSITE ARE PROVIDED BY PANASONIC ON AN “AS IS” AND “AS AVAILABLE” BASIS UNLESS OTHERWISE
                SPECIFIED IN WRITING. PANASONIC MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED,
                AS
                TO THE OPERATION OF THIS WEBSITE OR THE INFORMATION, CONTENT, OR MATERIALS INCLUDED ON, OR OTHERWISE
                MADE
                AVAILABLE TO YOU THROUGH, THIS WEBSITE, UNLESS OTHERWISE SPECIFIED IN WRITING. YOU EXPRESSLY AGREE THAT
                YOUR
                USE OF THIS WEBSITE IS AT YOUR SOLE RISK.</p>
            <p>PANASONIC AND ITS SUPPLIERS SHALL NOT BE LIABLE FOR ANY INDIRECT, SPECIAL, CONSEQUENTIAL, EXEMPLARY, OR
                INCIDENTAL DAMAGES INCLUDING, WITHOUT LIMITATION, LOST PROFITS OR REVENUES, LOSS OR DAMAGE TO DATA
                ARISING
                OUT OF THE USE OR INABILITY TO USE THIS WEBSITE, OR DAMAGES RESULTING FROM USE OR RELIANCE ON THE
                INFORMATION PRESENT ON THIS WEBSITE EVEN IF PANASONIC OR ITS SUPPLIERS HAVE BEEN ADVISED OF THE
                POSSIBILITY
                OF SUCH DAMAGES.</p>
            <p>Panasonic assumes no responsibility and shall not be liable for any delayed, lost, or misdirected
                communications through its website. Although Panasonic attempts to provide accurate information on the
                website, information on this website may contain technical inaccuracies or typographical errors. The
                content
                of any documents on the website is believed to be current and accurate as of its publication date.
                Notwithstanding the foregoing, Panasonic assumes no responsibility for the accuracy of the information.
                Panasonic may change the services or products mentioned at any time without notice. The mention of
                non-Panasonic products or services is for informational purposes only and constitutes neither an
                endorsement
                nor a recommendation.</p>
            <p>Panasonic makes no representation that the information on this website is appropriate for use outside of
                the
                United States of America (“U.S.”). Those who choose to access this website from outside the U.S. do so
                on
                their own initiative and are responsible for compliance with any applicable local laws. You may not
                access,
                download, use or export the information, software, products or services contained on this website in
                violation of U.S. export laws or regulations, or in violation of any applicable local laws or
                regulations.
                This website may contain links to other websites. Panasonic is not responsible for the privacy practices
                or
                the content of such websites, even if these websites appear as part of the Panasonic website.</p>
            <p>Nothing in these Terms of Use shall be interpreted to create a legal relationship between you and
                Panasonic
                and shall not be read, under any circumstances, to create an offer, acceptance of an offer, or otherwise
                bind Panasonic whatsoever nor may these Terms of Use be construed to create any liability or obligation
                to
                you or any third party.</p>
            <p>JURISDICTION</p>
            <p>This website and any disputes arising therefrom shall be governed by, and construed in accordance with,
                the
                laws of the State of New York without regard to the conflict of laws principles thereof. Any legal
                action or
                proceeding relating to your access to, or use of, this website shall be instituted in a state or federal
                court in New York.</p>
            <p>WEBSITE PRIVACY STATEMENT</p>
            <p>Panasonic has created this privacy statement in order to disclose our information gathering and
                dissemination
                practices for this website.</p>
            <p>Panasonic may use your IP address to help diagnose problems with our server and to administer the
                website.
                Your IP address may be used to identify you and allows Panasonic to gather broad demographic information
                on
                website visitors.</p>
            <p>Parts of this website may use a browser feature called “cookies.” A cookie is a small data file that
                certain
                websites write to your hard drive when you visit them. A cookie file can contain information such as a
                user
                ID that the website uses to track the pages you’ve visited or information you provide for personalized
                content in the MyIFE section of the website.</p>
            <p>The only personal information a cookie can contain is information you supply yourself. A cookie cannot
                read
                data from your hard disk or read cookie files created by other websites. A cookie will not corrupt your
                hard
                drive or transmit viruses to your computer.</p>
            <p>TO CONTACT US</p>
            <p>Please be advised that any form of communication sent to this website, be it ideas, suggestions or any
                other
                e-mail will be considered non-confidential and non-proprietary.</p>
            <p>If you have any questions about this privacy statement, the practices of this website, your dealings with
                this website, or if you wish to unsubscribe from our mailing list please contact:</p>
            <p>Panasonic Webmaster</p>
            <p>Panasonic Avionics Corporation</p>
            <p>26200 Enterprise Way</p>
            <p>Lake Forest, CA 92630 USA</p>
        </div>
    </Center>
);
