import {
    useEffect,
    useState
} from 'react';

import {Cache} from 'aws-amplify';

import {NextButton} from "@pac/platform-ui-components";

import {AiOutlineCloudDownload} from 'react-icons/ai';

import {I18n} from '@aws-amplify/core';

import {
    Box,
    Center
} from '@mantine/core';
import config from "../../../config";


const {about} = config;

const METADATA_LOCAL_STORAGE_KEY = 'pac-app-metadata';
const METADATA_LOCAL_STORAGE_VALUE = {
    version: 1,
};

export const VersionControl = () => {
    const [currentVersion, setCurrentVersion] = useState(false);

    useEffect(() => {
        const metadata = Cache.getItem(METADATA_LOCAL_STORAGE_KEY, {
            callback: () => {
                return METADATA_LOCAL_STORAGE_VALUE;
            },
        });

        setCurrentVersion(metadata.version);
    }, []);

    const handleUpgrade = () => {
        // clear
        Cache.clear();
        // store
        Cache.setItem(METADATA_LOCAL_STORAGE_KEY, {
            version: about.latestVersion,
        });
        window.location.href = about.host + '/about';
    };


    return (
        <>
            <tr>
                <td>
                    <Box mt="xl">
                        {I18n.get('Latest Version')}:
                    </Box>
                </td>
                <td>
                    <Box mt="xl">
                        <Center>{about.latestVersion}</Center>
                    </Box>
                </td>

                <td>
                    <div></div>
                </td>
            </tr>

            <tr>
                <td>
                    <Box mt="xl">
                        {I18n.get('Current Version')}:
                    </Box>
                </td>
                <td>
                    <Box mt="xl">
                        <Center>{currentVersion}</Center>
                    </Box>
                </td>
                <td>
                    {currentVersion < about.latestVersion ? (
                        <NextButton
                            type="button"
                            leftIcon={<AiOutlineCloudDownload/>}
                            onClick={handleUpgrade}
                        >
                            {I18n.get('Upgrade')}
                        </NextButton>
                    ) : <div></div>}
                </td>
            </tr>
        </>
    );

};
