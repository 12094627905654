import {
    AiOutlineClose,
    AiOutlineDelete,
    AiOutlineLogout,
    AiOutlineMenu
} from 'react-icons/ai';

import {
    Button,
    Menu
} from '@mantine/core';


import {IconContext} from 'react-icons';

import {I18n} from '@aws-amplify/core';

export const SmallProfileOverflowMenu = (
    {
        onSignOut,
        icon = (
            <IconContext.Provider value={{
                size: '20px',
                color: '#2a7fc9'
            }}>
                <AiOutlineLogout/>
            </IconContext.Provider>
        ),
        iconTitle = I18n.get('Sign out'),
        onSignOutAndForget,
        onHideAccount,
    }: {
        onSignOut: () => void,
        icon?: JSX.Element,
        iconTitle?: string,
        onSignOutAndForget: () => void,
        onHideAccount: () => void,
    }) => {
    return (
        <Menu shadow="md">
            <Menu.Target>
                <Button variant="subtle" color="gray">
                    <AiOutlineMenu/>
                </Button>
            </Menu.Target>

            <Menu.Dropdown>
                <Menu.Item icon={icon} onClick={onSignOut}>
                    {iconTitle}
                </Menu.Item>
                <Menu.Item
                    icon={<AiOutlineClose size={20}/>}
                    onClick={onHideAccount}
                >
                    {I18n.get('Forget')}
                </Menu.Item>
                <Menu.Item
                    icon={<AiOutlineDelete size={20}/>}
                    onClick={onSignOutAndForget}
                >
                    {I18n.get('Sign out and Forget')}
                </Menu.Item>
            </Menu.Dropdown>
        </Menu>
    );
};