import {stage} from "./stage.ts";

const portal = {
    prd: 'https://portal.nextcloud.aero',
    test: 'https://portal-test.nextcloud.aero',
    dev: 'https://portal-dev.nextcloud.aero',
    ide: ['https://localhost', 8081].join(':'),
} as { [key: string]: any };

export default portal[stage];
