import {useEffect} from 'react';

import {I18n} from '@aws-amplify/core';

import {
    Stack,
    Title
} from '@mantine/core';

import {useParams} from 'react-router-dom';

import {RecordForm} from '../forms/RecordForm.js';
import {useSignInProfile} from "../hooks/useSignInProfile.ts";
import {useSignInProfiles} from "../hooks/useSignInProfiles.ts";
import {PlatformLoader} from '@pac/platform-ui-components';

export const EditPage = () => {

    const {id} = useParams();
    const {
        record,
        handleUpdateSignInProfile
    } = useSignInProfile(id as string)
    const {handleFetchSignInProfiles} = useSignInProfiles()

    useEffect(() => {
        handleFetchSignInProfiles();
    }, []);


    if (!record) {
        return (<PlatformLoader message="Loading Sign-In Options...Please wait!"/>);
    }

    return (
        <Stack spacing="xl">
            <Title order={4}>{I18n.get('Sign-in Options')}</Title>
            <RecordForm
                record={record}
                handleSubmit={handleUpdateSignInProfile}
            />
        </Stack>
    );
};
