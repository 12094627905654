import {
    allApplicationsSelector,
    applicationsSelector
} from "../selectors";
import {
    deleteAllApplications,
    deleteApplication,
    fetchApplications,
    swapPositions,
} from '../reducers/recordsSlice';
import portalAppUrl from "../../../config/portal";
import {
    useAppDispatch,
    useAppSelector
} from "../../../hooks/useTypedReactRedux.ts";
import {RootState} from "../../../store";
import {useSignInProfiles} from "../../sign-in-profiles/hooks/useSignInProfiles.ts";
import {Application} from "../types.ts";

export const useApplications = () => {

    const dispatch = useAppDispatch();

    const {activeProfile} = useSignInProfiles();
    const max_return_to = activeProfile?.maxReturnTo;


    const applications = useAppSelector((state: RootState) =>
                                            applicationsSelector(state, max_return_to)
    );

    const allApplications = useAppSelector((state: RootState) =>
                                               allApplicationsSelector(state)
    );

    // get all non default applications
    const nonDefaultApplications = allApplications.filter((app: Application) => app.isDefault === false) as Application[];

    const handleFetchApplications = () => {
        dispatch(fetchApplications());
    };


    const handleDeleteApplication = (id: string) => {
        dispatch(deleteApplication(id));
    };

    const handleDeleteAllApplications = () => {
        dispatch(deleteAllApplications());
    };

    const handleDeleteNonDefaultApplications = () => {

        // delete all non default applications
        const ids = nonDefaultApplications.map((application) => application.id);
        ids.forEach((id) => {
            dispatch(deleteApplication(id))
        });
        return true
    };

    const handleSwapPositions = () => {
        dispatch(swapPositions());
    };

    const firstApplication = applications && applications.length > 0 ? applications[0] : {
        origin: portalAppUrl,
        pathname: '/',
    }


    return {
        applications,
        nonDefaultApplications,
        firstApplication,
        handleFetchApplications,
        handleDeleteApplication,
        handleDeleteAllApplications,
        handleSwapPositions,
        handleDeleteNonDefaultApplications,
    }
}
