export default {
    Home: 'Home',
    'Sign In': 'Sign In',
    'Sign Out': 'Sign Out',
    'Signing In': 'Signing In',
    'Signing Out': 'Signing Out',
    'Signed In with': 'Signed In with',
    'to continue to': 'to continue to',
    Next: 'Next',
    'get verification code': 'get verification code',
    Working: 'Working',
    'Please wait': 'Please wait',
    'Select Sign In Method': 'Select Sign In Method',
    Continue: 'Continue',
    'Continue with SSO': 'Continue with SSO',
    'Continue with password': 'Continue with password',
    'You have enabled Sign-In using both password and Single Sign-On (SSO)':
        'You have enabled Sign-In using both password and Single Sign-On (SSO)',
    'Please make a selection below or change sign-in options':
        'Please make a selection below or change sign-in options',
    Name: 'Name',
    'Email Address': 'Email Address',
    Password: 'Password',
    Preferences: 'Preferences',
    'Forgotten password': 'Forgotten password',
    Language: 'Language',
    Submit: 'Submit',
    Cancel: 'Cancel',
    Update: 'Update',
    or: 'or',
    'Terms Of Service': 'Terms Of Service',
    'Privacy Policy': 'Privacy Policy',
    'Contact Us': 'Contact Us',
    'Pick an account': 'Pick an account',
    'Return to': 'Return to',
    About: 'About'
};
