import {API} from '@aws-amplify/api';
import Amplify from '@aws-amplify/core';

// get config
import {stage} from "../config/stage.ts";
import amplifyConfig from '../config/amplify/index.ts'

API.configure(amplifyConfig);

stage === 'ide'
    ? (Amplify.Logger.LOG_LEVEL = 'DEBUG')
    : (Amplify.Logger.LOG_LEVEL = 'INFO');

// let url = new URL(window.location.href);
// let params = new URLSearchParams(url.search);

const AUTHORIZE_CACHE_KEY = 'pac-authorize-info';
const info = sessionStorage.getItem(AUTHORIZE_CACHE_KEY);
const account = JSON.parse(info as string);

// if (params.has('state')) {
//     const state = params.get('state');
//     const codeVerifier = sessionStorage.getItem(`codeVerifier-${state}`);
//     sessionStorage.removeItem(`codeVerifier-${state}`);
//     if (codeVerifier === null) {
//         throw new Error('Unexpected code');
//     }
// }

if (
    account !== null &&
    typeof account.cognitoUserPoolId !== 'undefined' &&
    account.cognitoUserPoolId !== ''
) {
    amplifyConfig.Auth.userPoolId = account.cognitoUserPoolId;
    amplifyConfig.Auth.userPoolWebClientId = account.appClientId;
    amplifyConfig.Auth.oauth.domain = [
        account.cognitoUserPoolDomain,
        'auth.us-west-2.amazoncognito.com',
    ].join('.');

    Amplify.configure(amplifyConfig);
    //Auth.configure(amplifyConfig.Auth);
}
