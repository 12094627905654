import BaseService from "../../../services/BaseService.ts";

const apiName = 'TenantManagementServiceAPI';
const collectionPath = '/tenants';

export const fetchRecords = (query: object) => {
    const baseService = new BaseService(apiName, collectionPath);
    return baseService.fetchRecords(query);
};

export const fetch = (id: string) => {
    const baseService = new BaseService(apiName, collectionPath);
    return baseService.fetch(id);
};

export const add = (payload: object) => {
    const baseService = new BaseService(apiName, collectionPath);
    return baseService.add(payload);
};

export const update = (id: string, payload: object) => {
    const baseService = new BaseService(apiName, collectionPath);
    return baseService.update(id, payload);
};

export const deleteRecord = (id: string) => {
    const baseService = new BaseService(apiName, collectionPath);
    return baseService.deleteRecord(id);
};

export const fetchUserWithLocation = async (location: string) => {
    const baseService = new BaseService(apiName, collectionPath);
    let path = location;
    let options = {};
    return await baseService.makeGetApiCall(apiName, path, options);
};

export const updateTermsAndConditions = (id: string) => {
    const baseService = new BaseService(apiName, collectionPath);
    const payload = {'tc-accepted': true};
    return baseService.patch(id, payload);
};

export const fetchApplications = async (customerId: string, query: { offset: number, limit: number } = {
    offset: 0,
    limit: 100
}, callback: () => void) => {
    const baseService = new BaseService(apiName, collectionPath);

    let path = '/tenant/' + customerId + '/app';

    let headers = {};

    let queryStringParameters = {
        offset: 0,
        limit: 100
    };
    // overwrite
    queryStringParameters = query;

    let options = {
        headers: headers,
        queryStringParameters: queryStringParameters,
    };

    const promise = baseService.makeGetApiCall(apiName, path, options)
    if (promise) {
        promise.then(callback);
    }
};
