import {
    Route,
    Routes
} from 'react-router-dom';
import {ConfirmPage} from "./pages/ConfirmPage.tsx";
import {SelectionPage} from "./pages/SelectionPage.tsx";
import {RequestPage} from "./pages/RequestPage.tsx";
import {FederatedUserPage} from "./pages/FederatedUserPage.tsx";

export const ResetPasswordLayout = () => {
    return (
        <Routes>
            <Route path="confirm" element={<ConfirmPage/>}/>
            <Route path="selection" element={<SelectionPage/>}/>
            <Route path="request" element={<RequestPage/>}/>
            <Route path="federated-user" element={<FederatedUserPage/>}/>
        </Routes>
    );
};
