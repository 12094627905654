import {
    createContext,
    ReactNode,
    useEffect
} from 'react';
import PropTypes from 'prop-types';

import {activeApplicationSelector} from "../selectors.ts";
import {
    fetchApplications,
    saveApplication
} from "../reducers/recordsSlice.ts";
import {
    useAppDispatch,
    useAppSelector
} from "../../../hooks/useTypedReactRedux.ts";
import {RootState} from "../../../store";
import {Application} from "../types.ts";

type ApplicationsContextType = {
    activeApplication: Application | null
}


export const ApplicationsContext = createContext<ApplicationsContextType>({
                                                                              activeApplication: null
                                                                          });

export const ApplicationsContextProvider = ({children}: { children: ReactNode }) => {
    const dispatch = useAppDispatch();

    // current looks like this - https://login.nextcloud.aero?returnTo=https://myapp.nexcloud.aero/my-path?foo=bar&zet=zero
    const current = new URL(window.location.href);

    const params = new URLSearchParams(current.search);

    // location search looks like this - ?returnTo=https://myapp.nexcloud.aero/my-path?foo=bar&zet=zero;
    const locationSearch = window.location.search;
    // console.debug('location search', locationSearch);

    useEffect(() => {
        if (params.has('returnTo')) {
            const returnToUrl = params.get('returnTo');
            const url = new URL(returnToUrl as string);

            // delimiter looks like this - ?returnTo=https://myapp.nexcloud.aero/my-path;
            const delimiter = '?returnTo=' + url.origin + url.pathname;
            // console.debug('delimiter', delimiter);

            // returnToSearch looks like this - ?foo=bar&zet=zero;
            let returnToSearch = locationSearch.substring(delimiter.length);
            // console.debug('returnToSearch', returnToSearch);

            if (returnToSearch.charAt(0) === '?') {
                returnToSearch = returnToSearch.substring(1);
            }

            const application = {
                id: `application-${Date.now()}`,
                returnToSearch: returnToSearch,
                host: url.host,
                hostname: url.hostname,
                origin: url.origin,
                pathname: url.pathname,
                port: url.port,
                protocol: url.protocol,
                isDefault: false,
            } as Application;

            dispatch(saveApplication(application));
        }
    }, [current.search, dispatch]);

    useEffect(() => {
        if (!params.has('returnTo')) {
            setTimeout(() => {
                dispatch(fetchApplications());
            }, 1000);
        }
    }, [current.search, dispatch]);

    const activeApplication = useAppSelector((state: RootState) =>
                                                 activeApplicationSelector(state)
    );

    return (
        <ApplicationsContext.Provider value={{activeApplication}}>
            {children}
        </ApplicationsContext.Provider>
    );
};

ApplicationsContextProvider.propTypes = {
    children: PropTypes.object,
};
