import {
    createAsyncThunk,
    createSlice
} from '@reduxjs/toolkit';

import {updateTermsAndConditions} from '../services/UserAgreementsService';

const entityKey = 'userAgreements';

const initialState = {
    result: '',
    loading: false,
    error: '',
};

// genenrates pending, fulfilled and rejected
export const updateTermsOfUseAcceptance = createAsyncThunk(
    `${entityKey}/updateTermsOfUseAcceptance`,
    (record: { id: string }) => {
        return updateTermsAndConditions(record.id)
            .then((updateResult) => {
                return updateResult;
            });
    }
);


const userAgreementsSlice = createSlice({
                                            name: entityKey,
                                            initialState,
                                            reducers: {
                                                clearState: () => initialState,
                                            },
                                            extraReducers: (builder) => {
                                                // RUN UPDATE
                                                builder.addCase(updateTermsOfUseAcceptance.pending, (state) => {
                                                    state.loading = true;
                                                });

                                                builder.addCase(
                                                    updateTermsOfUseAcceptance.fulfilled,
                                                    (state, action) => {
                                                        if (action.payload === 'success') {
                                                            state.result = 'success';
                                                            state.loading = false;
                                                        } else {
                                                            state.result = 'error';
                                                            state.loading = false;
                                                        }
                                                    }
                                                );

                                                builder.addCase(
                                                    updateTermsOfUseAcceptance.rejected,
                                                    (state, action) => {
                                                        state.loading = false;
                                                        state.error = action.error.message as string;
                                                    }
                                                );
                                            },
                                        });

export default userAgreementsSlice.reducer;
