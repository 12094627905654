import {stage} from "./stage.ts";

const config = {
    prd: {
        region: 'us-west-2',
        consoleDistributionUrl: 'https://console-dev.nextcloud.aero/assets',
        platform: {
            imagesBucket: 'pac-platform-images-us-west-2',
            imagesDistributionUrl: 'https://d2g0vtxljsrm9e.cloudfront.net',
        },
    },
    test: {
        region: 'us-west-2',
        consoleDistributionUrl: 'https://console-dev.nextcloud.aero/assets',
        platform: {
            imagesBucket: 'pac-platform-images-us-west-2',
            imagesDistributionUrl: 'https://d2g0vtxljsrm9e.cloudfront.net',
        },
    },
    dev: {
        region: 'us-west-2',
        consoleDistributionUrl: 'https://console-dev.nextcloud.aero/assets',
        platform: {
            imagesBucket: 'pac-platform-images-us-west-2',
            imagesDistributionUrl: 'https://d2g0vtxljsrm9e.cloudfront.net',
        },
    },
    ide: {
        region: 'us-west-2',
        consoleDistributionUrl: 'https://console-dev.nextcloud.aero/assets',
        platform: {
            imagesBucket: 'pac-platform-images-us-west-2',
            imagesDistributionUrl: 'https://d2g0vtxljsrm9e.cloudfront.net',
        },
    },
} as { [key: string]: any };

export default config[stage];
