import Auth from './auth.ts';
import API from './api.ts';
import Analytics from './analytics.ts';
import Storage from './storage.ts';

let config = {
    Auth: Auth,
    API: API,
    Analytics: Analytics,
    Storage: Storage,
};

export default config;
