import {stage} from './stage';

const rum = {
    prd: {
        isEnabled: true,
        applicationId: 'b5f8fffa-c332-4b35-9b41-3b71c9131cd8',
        applicationVersion: '1.0.0',
        applicationRegion: 'us-west-2',
        config: {
            sessionSampleRate: 1,
            guestRoleArn:
                'arn:aws:iam::827098250628:role/pac-idam-login-CognitoIde-CognitoUnAuthenticatedId-RTO3GS91FAXB',
            identityPoolId: 'us-west-2:68a710d8-3068-4fbb-b147-af24c30e2a8d',
            endpoint: 'https://dataplane.rum.us-west-2.amazonaws.com',
            telemetries: ['errors', 'performance', 'http'],
            allowCookies: true,
            enableXRay: true,
        },
    },
    test: {
        isEnabled: false,
        applicationId: '4412731e-2d68-4d64-9202-ed45f014c778',
        applicationVersion: '1.0.0',
        applicationRegion: 'us-west-2',
        config: {
            sessionSampleRate: 1,
            guestRoleArn:
                'arn:aws:iam::766605398863:role/pac-idam-login-CognitoIde-CognitoUnAuthenticatedId-RTF8IJWONKVU',
            identityPoolId: 'us-west-2:b24d69a8-ea6d-44ac-a2f3-7862de54d31a',
            endpoint: 'https://dataplane.rum.us-west-2.amazonaws.com',
            telemetries: ['errors', 'performance', 'http'],
            allowCookies: true,
            enableXRay: true,
        },
    },
    dev: {
        isEnabled: false,
        applicationId: 'db99c1ef-9822-498d-b3ba-9474c6159ace',
        applicationVersion: '1.0.0',
        applicationRegion: 'us-west-2',
        config: {
            sessionSampleRate: 1,
            guestRoleArn:
                'arn:aws:iam::364161771924:role/pac-idam-login-CognitoIde-CognitoUnAuthenticatedId-1GVX1EE66BFJM',
            identityPoolId: 'us-west-2:1760251d-ab6e-48d0-9a8f-388aac6bcf88',
            endpoint: 'https://dataplane.rum.us-west-2.amazonaws.com',
            telemetries: ['errors', 'performance', 'http'],
            allowCookies: true,
            enableXRay: true,
        },
    },
    ide: {
        isEnabled: false,
        applicationId: 'db99c1ef-9822-498d-b3ba-9474c6159ace',
        applicationVersion: '1.0.0',
        applicationRegion: 'us-west-2',
        config: {
            sessionSampleRate: 1,
            guestRoleArn:
                'arn:aws:iam::364161771924:role/pac-idam-login-CognitoIde-CognitoUnAuthenticatedId-1GVX1EE66BFJM',
            identityPoolId: 'us-west-2:00fa6f33-0ffc-4e24-8cd7-b34bfb6ffd1d',
            endpoint: 'https://dataplane.rum.us-west-2.amazonaws.com',
            telemetries: ['errors', 'performance', 'http'],
            allowCookies: true,
            enableXRay: true,
        },
    },
} as { [key: string]: any };


let RUM = {
    isEnabled: rum[stage].isEnabled,
    applicationId: rum[stage].applicationId,
    applicationVersion: rum[stage].applicationVersion,
    applicationRegion: rum[stage].applicationRegion,
    config: rum[stage].config,
};

export default RUM;
