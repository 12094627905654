import * as Yup from 'yup';

// const passwordRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})");

// original 
// const emailRegex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
const emailRegex = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

export const ValidationSchema = Yup.object()
                                   .shape({
                                              'email': Yup.string()
                                                          .min(3, 'Too Short!')
                                                          .matches(emailRegex, "Invalid Email Address")
                                                          .required('Required'),
                                              'password': Yup.string()
                                                             .min(8, 'Too Short! It must be at least 8 characters long')
                                                             .max(100, 'Too Long!')
                                                  // .matches(passwordRegex, "Password Does not match pattern")
                                                             .required('Required'),
                                          });

