import {
    Navigate,
    Route,
    Routes
} from 'react-router-dom';


import {IndexPage as AboutPage} from './features/about/pages/IndexPage';
import {Notifications} from '@mantine/notifications';
import {AuthorizePage} from "./features/oauth2/pages/AuthorizePage";
import SignInProfilesLayout from "./features/sign-in-profiles/layout.tsx";
import {PageNotFoundPage} from "./features/home/pages/PageNotFoundPage.tsx";
import {OfflinePage} from "./features/home/pages/OfflinePage.tsx";
import {IndexPage} from "./features/home/pages/IndexPage.tsx";
import {AccountsContextProvider} from "./features/accounts/context/AccountsContext.tsx";
import {ApplicationsContextProvider} from "./features/applications/context/ApplicationsContext.tsx";
import {AccountsLayout} from "./features/accounts/layout.tsx";
import AppLayout from "./layout/AppLayout.tsx";
import {ThemeProvider} from "./layout/ThemeProvider.tsx";
import {useState} from 'react';
import {
    ColorScheme,
    ColorSchemeProvider
} from '@mantine/core';


// var cookies = document.cookie.split(";");
// var cookies = document.cookie;


const App = () => {

    const [colorScheme, setColorScheme] = useState<ColorScheme>('light');
    const toggleColorScheme = (value?: ColorScheme) =>
        setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'));

    return (
        <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
            <ThemeProvider theme={{colorScheme}}>
                <AccountsContextProvider>
                    <ApplicationsContextProvider>
                        <>
                            <Notifications position="bottom-left" zIndex={2077}/>
                            <Routes>
                                <Route path="/" element={<AppLayout/>}>
                                    <Route
                                        path="login"
                                        element={<Navigate to="/redirect"/>}
                                    />
                                    <Route
                                        path="oauth2/authorize*"
                                        element={<AuthorizePage/>}
                                    />

                                    <Route
                                        path="accounts/*"
                                        element={<AccountsLayout/>}
                                    />

                                    <Route
                                        path="sign-in-profiles/*"
                                        element={<SignInProfilesLayout/>}
                                    />

                                    <Route path="/index.html" element={<IndexPage/>}/>

                                    <Route path="/" element={<IndexPage/>}/>
                                    <Route path="/about" element={<AboutPage/>}/>
                                    <Route path="/404" element={<PageNotFoundPage/>}/>
                                    <Route path="/offline" element={<OfflinePage/>}/>
                                    <Route path="*" element={<PageNotFoundPage/>}/>
                                </Route>

                            </Routes>
                        </>
                    </ApplicationsContextProvider>
                </AccountsContextProvider>
            </ThemeProvider>
        </ColorSchemeProvider>
    )
};

// Hub.listen(/.*/, (hubData) => {
//     const { event, data } = hubData.payload;
//     console.debug('EVENT', event);
//     console.debug('DATA', data);

//     if (event === 'networkStatus') {
//         console.debug(`User has a network connection: ${data.active}`);
//     }
// });

export default App;