import {
    MantineProvider,
    MantineThemeOverride
} from '@mantine/core';
import {ReactNode} from "react";


const headings = {
    // properties for all headings
    fontWeight: 400,
    fontFamily: 'Inter, Roboto, sans-serif',

    // properties for individual headings, all of them are optional
    sizes: {
        h1: {
            fontWeight: 100,
            lineHeight: 1.4,
        },
        h2: {
            lineHeight: 1.5
        },
        // ...up to h6
        h6: {fontWeight: 900},
    },
}

const myTheme = {
    colorScheme: 'light',
    fontFamily: 'Roboto, Inter',
    breakpoints: {
        xs: '500',
        sm: '800',
        md: '1000',
        lg: '1200',
        xl: '1400',
    },
    fontSizes: {
        xs: '12',
        sm: '14',
        md: '16',
        lg: '20',
        xl: '22',
    },
    headings: headings,
    colors: {
        panasonic: ['#194a74'],
    },
} as MantineThemeOverride;

export const ThemeProvider = ({
                                  theme,
                                  children
                              }: { theme: MantineThemeOverride, children: ReactNode }) => {

    const combinedTheme = {
        ...myTheme,
        ...theme
    };

    return (
        <MantineProvider
            withGlobalStyles
            withNormalizeCSS
            theme={combinedTheme}
        >
            {children}
        </MantineProvider>
    );
};

