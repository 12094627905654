import {UserAgreement} from "../types.ts";


export const UserAgreementsTable = ({agreements}: { agreements: UserAgreement[] }) => (<tbody>
<tr>
    <th>Name</th>
    <th>View</th>
    <th>Accepted By</th>
    <th>Accepted On</th>
    <th>From IP</th>
</tr>
{Array.isArray(agreements) && agreements.map((agreement, index) => {
    return (
        <tr key={index}>
            <td>{agreement.name}</td>
            {/*<td><NavLink to={agreement.viewPath}>View</NavLink></td>*/}
            <td>{agreement.acceptedBy}</td>
            <td>{agreement.acceptedOn}</td>
            <td>{agreement.acceptedIpAddress}</td>
        </tr>
    );
})}
</tbody>);
