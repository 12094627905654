import PropTypes from 'prop-types';
import {
    Formik,
    FormikProps
} from 'formik';

import ValidationSchema from './EmailFormValidationSchema.ts';


import {
    ActionBar,
    DisplayFormikState,
    FormWithActionBar,
    NextSubmitButton
} from '@pac/platform-ui-components';
import {Account} from "../types.ts";
import {FormComponent} from "./FormComponent.tsx";


export const EmailForm = ({
                              record,
                              handleSubmit
                          }: {
    record: Account,
    handleSubmit: (record: Account) => void,
}) => {
    // const renderForm = (props: FormikProps<Account>) => (
    //     <>
    //         <FormComponent {...props} />
    //         {false && <DisplayFormikState {...props} />}
    //     </>
    // );

    const renderForm = (props: FormikProps<Account>) => (
        <FormWithActionBar
            handleSubmit={props.handleSubmit}
            actionBar={<ActionBar primaryAction={<NextSubmitButton/>}/>}
        >
            <FormComponent {...props} />
            {false && <DisplayFormikState {...props} />}
        </FormWithActionBar>
    );


    return (
        <Formik
            initialValues={record}
            validationSchema={ValidationSchema}
            onSubmit={handleSubmit}
        >
            {renderForm}
        </Formik>
    );
};

EmailForm.propTypes = {
    record: PropTypes.object,
    handleSubmit: PropTypes.func,
    handleReset: PropTypes.func,
};
