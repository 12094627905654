import BaseService from "../../../services/BaseService.ts";

const apiName = 'TenantManagementServiceAPI';
const collectionPath = '/users';

export const updateTermsAndConditions = async (id: string) => {

    if (typeof id === 'undefined') {
        throw new Error('id is required parameter');
    }

    const baseService = new BaseService(apiName, collectionPath);
    const payload = {'tc-accepted': true};
    const response = await baseService.patch(id, payload);
    console.debug('UPDATE SERVICE TERMS RESPONSE: ', response);
    if (response.status === 204) {
        return 'success'
    } else {
        return 'failed'
    }
};
