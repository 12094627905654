import {
    useEffect,
    useState
} from 'react';

import {Amplify} from 'aws-amplify';
import {Auth} from '@aws-amplify/auth';

import {fetch} from '../features/users/services/UsersService';
import {Account} from "../features/accounts/types.ts";
import {
    CognitoUser,
    CognitoUserSession
} from 'amazon-cognito-identity-js';
import {NEXTUser} from "../features/users/types.ts";

export const useAuth = ({account}: { account: Account }) => {

    if (typeof account === 'undefined' || account === null) {
        console.log(
            'LOGIN APP. AUTH CONTEXT. ACCOUNT IS UNDEFINED OR NULL',
            account
        );
        throw new Error('Account is required parameter');
    }

    const [user, setUser] = useState<CognitoUser | null>(null);
    const [profile, setProfile] = useState<NEXTUser | null>(null);
    const [session, setSession] = useState<CognitoUserSession | null>(null);

    useEffect(() => {
        const run = async () => {
            try {
                const user = await Auth.currentAuthenticatedUser();
                console.log('LOGIN APP. AUTH CONTEXT. GOT USER', user);
                setUser(user);

                try {
                    const {
                        data: profile,
                        status,
                        statusText,
                    } = await fetch(user.username);

                    if (status !== 200) {
                        throw new Error(statusText);
                    }
                    console.log(
                        'LOGIN APP. AUTH CONTEXT. GOT PROFILE',
                        profile
                    );
                    setProfile(profile);
                } catch (error) {
                    console.trace(
                        'LOGIN APP. AUTH CONTEXT. FETCH PROFILE ERROR',
                        error
                    );
                }

                try {
                    const session = await Auth.userSession(user);
                    console.log(
                        'LOGIN APP. AUTH CONTEXT. GOT SESSION',
                        session
                    );
                    setSession(session);
                } catch (error) {
                    console.trace(
                        'LOGIN APP. AUTH CONTEXT. SESSION EXCEPTION: ',
                        error
                    );
                }
            } catch (error) {
                console.trace(
                    'LOGIN APP. AUTH CONTEXT. CURRENT USER EXCEPTION',
                    error
                );
            }
        };

        console.trace(
            'LOGIN APP. AUTH CONTEXT. RUN CONFIGURE FOR ACCOUNT',
            account.emailAddress
        );
        Amplify.configure({
                              Auth: {
                                  userPoolId: account.cognitoUserPoolId,
                                  userPoolWebClientId: account.appClientId,
                              },
                          });

        run();
    }, [account]);

    return {
        user,
        profile,
        session
    };
};
