import {RootState} from "../../store";
import {Application} from "./types.ts";

const key = 'applications';

// reversing the order using the added ts
export const sortApplicatons = (applications: Application[]) => {
    return applications.sort(function (first: Application, second: Application) {

        if (typeof first.position === 'undefined') {
            return 1;
        }

        if (typeof second.position === 'undefined') {
            return -1;
        }

        if (first.position < second.position) {
            return -1;
        }

        if (first.position > second.position) {
            return 1;
        }

        // names must be equal
        return 0;
    });
};

export const allApplicationsSelector = (state: RootState) => {
    const apps = state[key].records.ids.map((id: string) => {
        return state[key].records.byId[id];
    });
    return apps;

};

export const applicationsSelector = (state: RootState, max = 1) => {
    const apps = state[key].records.ids.map((id: string) => {
        return state[key].records.byId[id];
    });

    return apps.slice(0, max) as Application[];
};

export const activeApplicationSelector = (state: RootState) => {
    const [head, ..._] = applicationsSelector(state);
    return head;
};
